import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import isFilter from 'lodash.filter';
import isEmpty from 'lodash.isempty';
// import debounce from 'lodash.debounce';
// import moment from 'moment';
import StripeCheckoutButton from '../src/StripeCheckoutButton';
import logo from './logo.svg';
import { COMPONENT_STATIC_DATA } from './data/staticData';
import { countryNames } from './data/countryNames2';
import { statesInUSA } from './data/allStatesInUSA';
import industryNames from './data/industryNames2.json';
import {  
  updateUserPref,
  addCompanyType,
  addCompanyIndustry,
  addCompanySubIndustry,
  addCompanySector,
  addCompanySize,
  addCompanyRevenueSize,
  addCompanyStaffSize,
  addCompanyLocation,
  addCompanyOperationType,
  removeCompanyType,
  removeCompanyLocation,
  removeCompanySize,
  removeCompanySector,
  removeCompanyIndustry,
  removeCompanySubIndustry,
  removeCompanyOperationType,
} from './redux/actions';

// create all modals here: 

// =====================================================================================================================================================================
// other custom card components 
// eg custom headers, etc 


// =====================================================================================================================================================================
// Card Body for Price Cards
// we then import the different card body into the card layouts
// why is PriceCardContent rendering 6 times?
// {
//   "price_id": "price_1KcaTwCXj0cKCM7gi4Ab4obz",
//   "plan_title": "Yearly",
//   "amount": "120",
//   "product_items": [
//       "Billed annually",
//       "You save 30% on this plan!",
//       "You get everything in the Monthly plan and a discount!"
//   ]
// }

export const PriceCardContent = ({cardData}) => {  
  useEffect(() => {                        
    console.log('mounted PriceCardContent', cardData);
  }, [cardData]);  
  return <>
  <div 
    style={{
      width: '20rem',
      marginTop: '3rem',
    }}
    className="card"
  >  
    <img className="card-img-top" src={logo} alt="price card image" />
    <div className="card-body">
      <h5 
        style={{
          textAlign: 'center',
        }}
        className="card-title"
      >
          {cardData.plan_title}
      </h5>
      <p style={{
          fontWeight: 'bold',
          fontSize: '3rem',
          textAlign: 'center',
        }}
      className="card-text">
        {cardData.amount}
      </p>
    </div>
    <ul className="list-group list-group-flush">
    {cardData.product_items.length > 0 ? 
      cardData.product_items.map(lineItemText => (
        <>
          <li className="list-group-item">{lineItemText}</li>
        </>
      )) : <></>
    }
      {/* <li className="list-group-item">Cras justo odioCras justo odioCras justo odioCras justo odioCras justo odioCras justo odioCras justo odioCras justo odio</li>
      <li className="list-group-item">Dapibus ac facilisis in</li>
      <li className="list-group-item">Vestibulum at eros</li> */}
    </ul>
    <div className="card-body">
      {/* <a href="#" className="card-link">Card link</a>
      <a href="#" className="card-link">Another link</a> */}
      <StripeCheckoutButton planType={cardData.plan_title}/>
    </div>
</div>
  </>
};  

// =====================================================================================================================================================================
// Card Body for Profile Cards

export const ProfileCardContent = ({}) => {  
  // learning points:
  // checked pro can use redux state stright in components no need to set state
  const useReduxState = () => {
    return useSelector(
      (state) => ({          
        // user pref states
        email: state.username,                 
        creationTime: state.creationTime,
        lastLogInTime: state.lastLogInTime,
        isMonthlyMember: state.isMonthlyMember,
        memberSince: state.memberSince,              
        invoicePaidState: state.invoicePaidState,
        subscriptionState: state.subscriptionState,
        invoice_items_list: state.invoice_items_list,
        // other states
        loadingTypes: state.loadingTypes,
        loginTime: state.loginTime,
      }),
      shallowEqual
    )};    
  const dispatch = useDispatch();     
  const funcName = `ProfileCardContent`;
  const { loadingTypes, loginTime, email, lastLogInTime, creationTime, isMonthlyMember, 
    memberSince, invoice_items_list, invoicePaidState, subscriptionState,
  } = useReduxState();
  const timeinTimeString = new Date(loginTime).toLocaleTimeString()
  const timeinDateString = new Date(loginTime).toLocaleDateString();
  const timeIn = `${timeinTimeString}   ${timeinDateString}`;
  const accountCreatedOn = new Date(parseInt(creationTime)).toLocaleDateString();  
  // const userLocation = Intl.DateTimeFormat().resolvedOptions().timeZone;
  // console.log(`userLocation`, userLocation);    
  // moment.tz.setDefault(userLocation);
  // moment(new Date()).set({ hour: 10, minute: 0, second: 0 }).toDate(),        
  // console.log('Navigator.geolocation', Navigator.geolocation);    
  // console.log(Intl.DateTimeFormat().resolvedOptions().timeZone)
  // dateOfBirth: `Date of Birth must be no later than ${moment().utc().subtract(1, 'days').format('DD-MM-YYYY')}`,    
  // const lastIn = new Date(parseInt(lastLogInTime)).toLocaleDateString();
  // let dateNow = new Date(Date.now()).toLocaleDateString();
  // return `${new Date(Date.now()).toLocaleTimeString()}-${dateNow.split('/').join('')}`;
  // const timeIn =  moment.utc(loginTime).format("YYYY-MM-DD HH:mm"); // format("MM/DD/YYYY");  
  // const lastIn =  moment.utc(parseInt(lastLogInTime)).format("HH:mm"); // format("MM/DD/YYYY");  
  // const accountCreatedOn = moment(new Date(parseInt(creationTime))).set({ hour: 10, minute: 0, second: 0 }).toDate()  
  // moment.utc(parseInt(creationTime)).format("MM/DD/YYYY")
  
  useEffect(() => {
    console.log('mounted ProfileCardContent', invoicePaidState, subscriptionState, invoice_items_list);        
    invoice_items_list.invoice_items_list.push(1)
    invoice_items_list.invoice_items_list.push(2)    
  }, [loginTime, email]);  
  
  return (
    <>  
      <div className="card company-card">                     
       {
        loginTime ? <>
            {<h5
            style={{
                // backgroundColor: 'var(--indigo)',                
                padding: '0.2rem',
                marginTop: '1rem',
                textAlign: 'center',
                fontWeight: 'bold',
            }}                
          >
              {`Profile Settings`}
        </h5>
       }
       <hr/>
       <table 
                id='mobileviewtable1'
                style={{
                  color: "#fff",      
                  marginTop: '1rem'                
                }}                             
                className="table">                
                <tbody>
                    {
                      <tr
                        style={{
                            background: 'var(--light)',
                            color: 'var(--dark)',
                          }}
                        >
                      <th scope="row"><td>Email:</td></th><td>{email}</td>                        
                      </tr>
                    }
                        {
                      <tr
                        style={{
                            background: 'var(--light)',
                            color: 'var(--dark)',
                          }}
                        >
                      <th scope="row"><td>Created account on:</td></th><td>{accountCreatedOn}</td>
                      </tr>
                    } 
                    {
                      <tr
                        style={{
                            background: 'var(--light)',
                            color: 'var(--dark)',
                          }}
                        >
                      <th scope="row"><td>Logged in at:</td></th><td>{timeIn}</td>                                              
                      </tr>
                    }                                   
                    {/* {
                      <tr
                        style={{
                            background: 'var(--light)',
                            color: 'var(--dark)',
                          }}
                        >
                      <th scope="row"><td>Last Logged in at:</td></th><td>{lastIn}</td>                        
                      </tr>
                    }          */}                                            
                    {
                      <tr
                        style={{
                            background: 'var(--light)',
                            color: 'var(--dark)',
                          }}
                        >
                      <th scope="row"><td>Subscription plan:</td></th><td>{isMonthlyMember ? `subscribed` : `not yet subscribed`}</td>
                      </tr>
                    }
                  </tbody>
            </table> 
              <div className="card-body">
                  <h5 className="card-title">Payment history</h5>              
                  {
                    invoice_items_list.invoice_items_list.length > 0 ? 
                    <><p className="card-text">There are {`${invoice_items_list.invoice_items_list.length}`} transactions in total. </p></>
                    : <></>
                  }
              </div>            
                <ul 
                  style={{
                      // marginTop: '1rem',                  
                      marginBottom: '1rem',
                  }}                        
                  className="list-group list-group-flush">
                  {invoice_items_list.invoice_items_list.length > 0 ? 
                    <>
                      {invoice_items_list.invoice_items_list.map(item => (
                        <>
                          {/* <li className="list-group-item">{`${item}`}</li> */}
                          <li className="list-group-item"><a href="#" className="card-link">Another {`${item}`}</a></li>
                        </>
                      ))}
                    </> : <>                    
                      <li className="list-group-item"> There are {`${invoice_items_list.item_count}`} transactions. </li>
                      {/* <li className="list-group-item">No payment transactions yet.</li> */}                      
                    </>
                  }                                        
                </ul>                                
                {invoice_items_list.invoice_items_list.length > 0 ? <>
                  <small 
                      style={{
                        marginTop: '1rem',                  
                        marginBottom: '1rem',
                      }}                    
                    className="form-text text-muted">Click on the purchased items to view your receipt online.</small>
                </> : <></>}                
                {/* <div 
                    style={{
                      marginTop: '3rem',
                      marginBottom: '2rem',                      
                    }}
                    className="d-flex flex-row-reverse"
                >
                    <a href="#" className="btn btn-primary">Save this card</a>              
                </div>   */}
        </> : <>
        {<h5
            style={{
                // backgroundColor: 'var(--indigo)',                
                padding: '0.2rem',
                marginTop: '1rem',
                textAlign: 'center',
                fontWeight: 'bold',
            }}                
          >
              {`Please login first.`}
        </h5>
       }
        </>
        }        
          </div>
    </>
)}; 


// =====================================================================================================================================================================
// Card Body for Company Cards

export const CompanyCardContent = ({cardData}) => {  
  useEffect(() => {                        
    console.log('mounted CompanyCardContent', cardData);
  }, [cardData]);  
  return (
    <>  
      <div className="card company-card">                     
       {
          <h5
            style={{
                // backgroundColor: 'var(--indigo)',                
                padding: '0.2rem',
                marginTop: '1rem',
                textAlign: 'center',
                fontWeight: 'bold',
            }}                
          >
              InterDigital Inc              
        </h5>
       }
       <hr/>
       <table 
                id='mobileviewtable1'
                style={{
                  color: "#fff",      
                  marginTop: '1rem'                
                }}                             
                className="table">                
                <tbody>
                    <tr
                     style={{
                        background: 'var(--light)',
                        color: 'var(--dark)',
                      }}
                    >
                    <th scope="row">                    
                    <td>Company location:</td>
                    </th>        
                    <td>
                        USA
                    </td>                        
                    </tr>

                    <tr
                      style={{
                        background: 'var(--light)',
                        color: 'var(--dark)',
                      }}
                    >
                        <th 
                        scope="row"                          
                        >                    
                        <td>Operating as:</td>
                        </th>                                                        
                        <td>grading contractor</td>                        
                    </tr>

                    <tr
                    style={{
                      background: 'var(--light)',
                      color: 'var(--dark)',
                    }}
                    >
                        <th 
                        scope="row"                          
                        >                    
                        <td>Revenue in the region of:</td>
                        </th>                                                        
                        <td>$124 million</td>                        
                    </tr>                  
                      </tbody>
            </table> 
            <div className="card-body">
                    <h5 className="card-title">News Highlights</h5>
                    <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
            </div>
            <hr/>
                <ul className="list-group list-group-flush">
                    <li className="list-group-item">Cras justo odio</li>
                    {/* <li className="list-group-item">Dapibus ac facilisis in</li>
                    <li className="list-group-item">Vestibulum at eros</li>
                    <li className="list-group-item">Vestibulum at eros</li>
                    <li className="list-group-item">Vestibulum at eros</li>
                    <li className="list-group-item">Vestibulum at eros</li>
                    <li className="list-group-item"><a href="#" className="card-link">Another link</a></li>
                    <li className="list-group-item"><a href="#" className="card-link">Another link</a></li>
                    <li className="list-group-item"><a href="#" className="card-link">Another link</a></li>                     */}
                </ul>
                <div className="card-body">
                    <a href="#" className="card-link">Card link</a>
                    <a href="#" className="card-link">Another link</a>
                </div>
                <p className="card-text">With supporting text below as a natural lead-in to additional content.</p>
                <div 
                    style={{
                      marginBottom: '2rem',                      
                    }}
                    className="d-flex flex-row-reverse"
                >
                    <a href="#" className="btn btn-primary">Save this card</a>              
              </div>  
          </div>
    </>
)}; 

// =====================================================================================================================================================================
// Card Body for UserPreferences
// const steps = [`companyType`, `location`, `companySize`, `sector`, `industry`, `sub-industry`, `operationType`];
// we need more than one Body Content component for UserPreferences
// Does the company have a website?
// Is the commpany listed on the stock exchange?
// What type of company are you interested in? Public or Private?
// What sector are you intersted in?
// What industry are you interested in? Show a list of industries
// What sub-industry are you intersted in?
// Are you interested in new or long-established companies?
// Enter the location you are interested in:
// how to search by operating type of company ?
// again search by keywords
// how to search by location? 
// again search by keywords
// which filters require check boxes for filter ?
// private or public company 
// revenue ranges
// has recent news or not 
// sort by most recent news to companies with the oldest news 
// - What type of companies arfe you interested in, Public or Private?
// - Which country?
// - Small, Medium or Large (employee size and revenue size)
// - What sector are you intersted in?
// - What industry are you intersted in?
// - What sub-industry are you intersted in?
// - Long-established companies or new companies?

export const SelectCompanyType = ({}) => {
    // user select flow:
    // user clicks on multiple options 
    // the selected option state is kept in the Select component until user clicks Next button
    // at which currentStep will change and this component will re-render 
    // but if user refresh component - then selected options are lost 
    // optimisation points:
    // we shuld persist every user selection in redux
    // optimisation points:
    // if in desktop view, need logic to change css of selected bars so they stay highlighted 
    // other points: 
    // on select the items stays highlighted     
    // and redux is updated 
    // and the user selection is shown at the top via tags in a table
    // tx.setAttribute("style", `height:${tx.scrollHeight}px;overflow-y:hidden;`);
    // background-color: var(--cyan);    
    // learning points:
    // It seems that changes within an Object are not properly watched so I decided to watch the exact key I need
    // leanring points:
    // do not use nested objects to represent states in redux 
    // companyType: state.userPref.companyType, // works for the first change 
    const useReduxState = () => {
      return useSelector(
        (state) => ({          
          // user pref states
          companyType: state.companyType,          
          // other states
          loadingTypes: state.loadingTypes,
          loginTime: state.loginTime,
        }),
        shallowEqual
      )};    
    const { loadingTypes, loginTime, companyType } = useReduxState();
    const dispatch = useDispatch();     
    const funcName = `SelectCompanyType`;      
    const options = [`Public companies`, `Private companies`, `Long-established companies`, `Recently founded companies`];
    useEffect(() => {                            
      console.log('mounted SelectCompanyType', companyType);
    }, [companyType]);

    // addTag using userPref nested object
    // const addTag = (e) => {        
    //   // get category of the tag just rmeoved e.g. companyLocation, companyType, etc    
    //   const selectedValue = e.currentTarget.innerHTML
    //   console.log(`exec addTag`, selectedValue);      
    //   // get current state for companyType in userPref in redux
    //   let currentState = userPref[userPrefKey];
    //   // update state in redux
    //   if(isEmpty(currentState)) {
    //     // get current state for tag name from redux annd merge data       
    //     let updateObj = {[userPrefKey]: [selectedValue]};
    //     let newUserPrefObj = Object.assign(userPref, updateObj);        
    //     dispatch(updateUserPref(newUserPrefObj));
    //   } else {        
    //     currentState.push(selectedValue);
    //     let newState = currentState;
    //     let updateObj = {[userPrefKey]: newState};
    //     let newUserPrefObj = Object.assign(userPref, updateObj);        
    //     dispatch(updateUserPref(newUserPrefObj));
    //   }            
    // };  

    const addTag = (e) => {        
      // get category of the tag just rmeoved e.g. companyLocation, companyType, etc    
      // get current state for companyType in userPref in redux      
      // let findTag = currentStateArrayRedux.find(item => selectedValue === item);
      // add new tag into array so can concat in reducer
      const selectedValue = e.currentTarget.innerHTML      
      let currentStateArrayRedux = companyType;      
      let findTag = currentStateArrayRedux.includes(selectedValue);
      console.log(`exec addTag findTag`, companyType, selectedValue, findTag);      
      if(findTag) {
        // may be can remove duplicate tag here 
        // as user's intention maybe to remove it
        return `tag already in redux`;
      }
      let dataArr = [selectedValue];
      let loadingType = funcName;
      dispatch(addCompanyType(dataArr, loadingType));
    };  

  return (
      <>            
        {<h4 style={{                
                  padding: '0.2rem',
                  marginTop: '1rem',                  
                  marginBottom: '3rem',
                  textAlign: 'center',
                  fontWeight: 'bold',
              }}
          >
              {`What type of companies are you interested in?`}              
              {/* {`Public or private companies?`} */}
          </h4>}
          {/* <div className="card-body">
                    <h5 className="card-title">News Highlights</h5>
                    <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
          </div>         */}
          {/* <p 
              style={{                                            
              // marginTop: '1rem',                      
          }} 
            className="card-text">Company locations: 
          </p> */}
          {/* <small 
          style={{                            
            // marginTop: '1rem',                  
            marginBottom: '1rem',            
          }}
          id="emailHelp" className="form-text text-muted">You can select multiple options.</small> */}
             <p 
                style={{                                            
                marginTop: '1rem',
                marginTop: '1rem',                      
            }} 
              className="card-text text-muted">
                {/* Company locations:  */}
                You can select multiple options.
            </p>
          <ul className="list-group list-group-flush">         
          {
          options.length > 0 ? 
            options.map(option => (
              <>
                <li 
                className="list-group-item highlight"
                onClick={(e) => addTag(e)}
              >
                {option}
              </li>
              </>
            )) : <></>
          }                 
          </ul>       
      </>
)};

export const SelectCompanySize = ({}) => {  
  // do we nened to re-render Select compoennts? No, as we are not persisting what the user has selected here
  // everything user sleected will be persisted and shown in the table in the UserPreference component  
  const useReduxState = () => {
    return useSelector(
      (state) => ({          
        // user pref states
        companySize: state.companySize,
        staffSize: state.staffSize,
        revenueSize: state.revenueSize,            
        // other states
        loadingTypes: state.loadingTypes,
        loginTime: state.loginTime,
      }),
      shallowEqual
    )};        
  const { staffSize, revenueSize } = useReduxState();
  const dispatch = useDispatch();     
  const funcName = `SelectCompanySize`;        
  const maxRevenue = 100000000;
  const maxStaffNumber = 10000;
  const minRevenue = 100000;
  const minStaffNumber = 100;
  const [salaryObj, setsalaryObj] = useState({'salary':`100,000`});
  const [staffObj, setNumberOfStaffObj] = useState({'staff':`100`});
  const [maxRevText, setMaxRevText] = useState(`or less`);
  const [maxStaffText, setMaxStaffText] = useState(`or less`);  
  
  useEffect(() => {
    console.log(`mounted ${funcName}`, staffSize, revenueSize);
  }, [staffSize, revenueSize]);

  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const updateRedux = (sizeType, sizeVal) => {
    // here we should check that the sizeVal ie new value is different to value in redux           
    if (!isEmpty(sizeType) && !isEmpty(sizeVal)) {             
      if(sizeType === 'salary') {
        if(sizeVal === revenueSize) {
          return true;
        }  
        console.log(`updateRedux`, typeof revenueSize, typeof sizeVal)
        dispatch(addCompanyRevenueSize(parseInt(sizeVal), funcName));
      }      
      if(sizeType === 'staff') {
        if(sizeVal === staffSize) {
          return true;
        }    
        console.log(`updateRedux`, staffSize, sizeVal)
        dispatch(addCompanyStaffSize(parseInt(sizeVal), funcName));
      }      
    }
  };
  
  // const delayedUpdate = useCallback(debounce(value => updateRedux('salary', value), 800), [])  

  const onChangeSlider = async (e) => {
      // flow:
      // on change we update component 
      // but to store in redux we use a debounce mode
      let newFieldObj = Object.assign({}, salaryObj);        
      let key = e.target.name
      let value = e.target.value                           
      console.log(`log onChangeSlider`, typeof value, key);         
      if(key === 'salary') {                
        newFieldObj[key]= numberWithCommas(value);
        setsalaryObj(newFieldObj);  
        // show wording "or above" if reach max value        
        if(maxRevenue === parseInt(value)) {
          console.log(`log max reached`, value);      
          setMaxRevText(`or above`)          
        } else {
          setMaxRevText(`or less`)
        }
        // update redux last with delay
        // debounce(updateRedux('salary', value), 800); // err: Unhandled Rejection (TypeError): Expected a function        
        setTimeout(() => {
          updateRedux('salary', value)
        }, 800);        
      }      
      if(key === 'staff') {
        newFieldObj[key]= numberWithCommas(value);
        setNumberOfStaffObj(newFieldObj);
        if(maxStaffNumber === parseInt(value)) {
          console.log(`log max reached`, value);      
          setMaxStaffText(`or above`)
        } else {
          setMaxRevText(`or less`)
        }
        // update redux last with delay
        // debounce(updateRedux('staff', value), 800); // err
        // debounce(value => updateRedux('staff', value), 800); // err
        setTimeout(() => {
          updateRedux('staff', value)
        }, 800);        
      }
  };  

return (
    <>            
      {<h4 style={{                
                padding: '0.2rem',
                marginTop: '1rem',                  
                marginBottom: '1rem',
                textAlign: 'center',
                fontWeight: 'bold',
            }}
        >            
            {`How big is the company?`}
        </h4>}                  
          
        <div className="card-body">   
        <span                                   
          style={{
              // marginLeft: '0.5rem',
              marginRight: '5rem',
              cursor: 'pointer',
              fontWeight: 'bolder',
        }}                                
        >Revenue range:</span>              
          <input 
              style={{                            
                  width: '80%',                                    
              }}
              name="salary" 
              type="range" 
              min={`${minRevenue}`}
              max={`${maxRevenue}`}
              step="50000"  
              // value={salaryObj["salary"]}
              onChange={(e) => onChangeSlider(e)}
          />                       
        </div>             
        <div className="card-body">  
        <span                                   
          style={{
              // marginLeft: '0.5rem',
              marginRight: '3rem',
              cursor: 'pointer',
              fontWeight: 'bolder',
        }}                                
        >Number of employees:</span>                 
          <input 
              style={{                            
                  width: '80%',                                    
              }}
              name="staff" 
              type="range" 
              min={`${minStaffNumber}`}
              max={`${maxStaffNumber}`}
              step="100"
              // value={staffObj["staff"]}
              onChange={(e) => onChangeSlider(e)}
          />                       
        </div>             

        <ul className="list-group list-group-flush">                
            <li className="list-group-item">
              <p 
                  style={{                                            
                  marginTop: '1rem',
                  marginTop: '1rem',
                  textAlign: 'center',                      
              }} 
                className="card-text"> 
                  <span                                   
                    style={{
                        // marginLeft: '0.5rem',
                        marginRight: '3rem',
                        // cursor: 'pointer',
                        fontWeight: 'bolder',
                  }}                                
                >Revenue:</span> ${salaryObj.salary} {maxRevText}             
                </p>
          </li>
          <li className="list-group-item">
              <p 
                  style={{                                            
                  marginTop: '1rem',
                  marginTop: '1rem',
                  textAlign: 'center',                      
              }} 
                className="card-text"> 
                  <span                                   
                    style={{
                        // marginLeft: '0.5rem',
                        marginRight: '3rem',
                        // cursor: 'pointer',
                        fontWeight: 'bolder',
                  }}                                
                >Headcount:</span> {staffObj.staff} {maxStaffText}
                </p>
          </li>
        </ul>       
    </>
)};

export const SelectLocation = ({}) => {  
  // optimisation points:
  // show a popular list first not a list of all countries 
  // add all big cities 
  // add all states for USA
  const addEmoji = (list) => {
    let newlist = list.map(obj => {
      let emoji = getFlagEmoji(obj.alpha2Code);
      obj['emoji'] = emoji;
      return obj;
    })    
    return newlist    
  }; 

  const useReduxState = () => {
    return useSelector(
      (state) => ({          
        // user pref states        
        companyLocation: state.companyLocation,                
        // other states
        loadingTypes: state.loadingTypes,
        loginTime: state.loginTime,
      }),
      shallowEqual
    )};    
  const { companyLocation } = useReduxState();
  const dispatch = useDispatch();         
  const originalCountryList = [...countryNames];
  const [optionList, setOptionList] = useState(addEmoji(originalCountryList));
  const [fieldObj, setFieldsText] = useState({});
  const obj1 = {name: '', ariaLabel: '', height: '2rem', width: ''}; 
  const funcName = `SelectLocation`;  

  useEffect(() => {                            
    console.log('mounted optionList', companyLocation, optionList);    
    // translateCountryCideToFlags();
  }, [companyLocation, optionList]);

  function getFlagEmoji(countryCode) {
    // getFlagEmoji('US') = 🇺🇸
    // getFlagEmoji('NL') = 🇳🇱
    // getFlagEmoji('CH') = 🇨🇭
    const codePoints = countryCode
      .toUpperCase()
      .split('')
      .map(char =>  127397 + char.charCodeAt());
    return String.fromCodePoint(...codePoints);
  }   
  


  const onFilter = (userInput) => {
    // learning points:
    // probalem with filter is that if list goes to length of 0 
    // then nothing to filter 
      console.log(`exec onFilter`, userInput)
      let results = [];
      const searchLength = 1;
      if (!isEmpty(userInput) && userInput.length >= searchLength) {
        results = isFilter(optionList,
          option => new RegExp(userInput.toLowerCase())
            .test(option.englishShortName.toLowerCase()));            
            console.log(`here here`, results)
            if(results.length === 0) {              
              results = originalCountryList;              
            }
      } else {
        // results equal original list
        results = originalCountryList;   
        console.log(`here here here`);
      }                                
      setOptionList(results);
      return true;      
  };        

  const onChange = async (e) => {              
      let newFieldObj = Object.assign({}, fieldObj);        
      let key = e.target.name
      let value = e.target.value        
      newFieldObj[key] = value; 
      setFieldsText(newFieldObj);  
      onFilter(value);             
  };  
  
  const addTag = (e, selectedOption) => {
    const selectedValue = e.currentTarget.innerHTML      
    let currentStateArrayRedux = companyLocation;      
    let findTag = currentStateArrayRedux.includes(selectedOption);
    console.log(`exec addTag findTag`, companyLocation, selectedValue, findTag, selectedOption);   
    if(findTag) {      
      return `tag already in redux`;
    }
    let dataArr = [selectedOption];
    let loadingType = funcName;
    dispatch(addCompanyLocation(dataArr, loadingType));
  };  

  return (
      <>            
       {<h4 style={{                
                padding: '0.2rem',
                marginTop: '1rem',
                textAlign: 'center',
                fontWeight: 'bold',
            }}>
            {`Which location are you intersted in?`}              
        </h4>}       
         <div className="card-body">                    
                  <div className="form-group">
                    <label for="exampleInputEmail1">Start typing and choose from the list of locations below: </label>
                    <input                       
                      className="form-control" 
                      id="exampleInputEmail1" 
                      aria-describedby="emailHelp" 
                      placeholder="e.g. Tokyo"
                      type="text"              
                      data-field={obj1.name}
                      aria-label={obj1.ariaLabel}
                      name={obj1.name}
                      value={fieldObj[obj1.name]}
                      onChange={(e) => onChange(e)}
                      />
                  </div>
                  <p 
                     style={{                                            
                      marginTop: '1rem',
                      marginTop: '1rem',                      
                  }} 
                    className="card-text text-muted">
                      {/* Company locations:  */}
                      You can select multiple options, up to a maximum of 15.
                  </p>
                  {/* <small 
                    style={{                            
                      marginTop: '3rem',                  
                      marginBottom: '1rem',            
                    }}
                    id="emailHelp" 
                    className="form-text text-muted"
                  >
                    You can select multiple options.
                  </small> */}
            </div>            
                <ul className="list-group list-group-flush">
                  <>
                  {optionList.length > 0 ? <>                    
                    {optionList.map((option, index) => (
                    <>                        
                        <li 
                          // style={{                                    
                          //   borderBottom: '2px solid black',
                          //   width: '100%',                                
                          // }}                            
                            key={index}
                            className="list-group-item highlight"
                            onClick={(e) => addTag(e, option.alpha2Code)}
                          >                                                      
                            {option.englishShortName} 
                            {/* <span>{option.alpha2Code}</span> */}
                            <span                                   
                                  style={{
                                      marginLeft: '0.5rem',
                                      cursor: 'pointer',
                                      fontWeight: 'bolder',
                                }}                                
                            >{option.emoji}</span>
                          </li>
                    </>                    
                ))}
                </> : <></>
                }   
                  </>            
                </ul>       
      </>
)};

// here baby

export const SelectSector = ({}) => {    
  const useReduxState = () => {
    return useSelector(
      (state) => ({                  
        sector: state.sector,                        
        loadingTypes: state.loadingTypes,
        loginTime: state.loginTime,
      }),
      shallowEqual
    )};    
  const { sector } = useReduxState();
  const dispatch = useDispatch();
  const industryKeywordList = Object.keys(industryNames);  
  const [optionList, setOptionList] = useState(industryKeywordList);
  const [fieldObj, setFieldsText] = useState({});
  const obj1 = {name: '', ariaLabel: '', height: '2rem', width: ''}; 
  const funcName = `SelectLocation`;
  
  useEffect(() => {                            
    // console.log(`industryNames`, Object.keys(industryNames));
    console.log('mounted SelectSector', sector);        
  }, [sector, optionList]);

  const onFilter = (userInput) => {    
      console.log(`exec onFilter`, userInput)
      let results = [];
      const searchLength = 1;
      if (!isEmpty(userInput) && userInput.length >= searchLength) {
        results = isFilter(optionList,
          option => new RegExp(userInput.toLowerCase())
            .test(option.toLowerCase()));            
            console.log(`here here`, results)
            if(results.length === 0) {              
              results = industryKeywordList;              
            }
      } else {
        // results equal original list
        results = industryKeywordList;   
        console.log(`here here here`);
      }                                
      setOptionList(results);
      return true;      
  };        

  const onChange = async (e) => {              
      let newFieldObj = Object.assign({}, fieldObj);        
      let key = e.target.name
      let value = e.target.value        
      newFieldObj[key] = value; 
      setFieldsText(newFieldObj);  
      onFilter(value);             
  };  
  
  const addTag = (e, selectedOption) => {
    const selectedValue = e.currentTarget.innerHTML      
    let currentStateArrayRedux = sector;      
    let findTag = currentStateArrayRedux.includes(selectedOption);
    console.log(`exec addTag findTag`, sector, selectedValue, findTag, selectedOption);   
    if(findTag) {      
      return `tag already in redux`;
    }
    let dataArr = [selectedOption];
    let loadingType = funcName;
    dispatch(addCompanySector(dataArr, loadingType));
  };  

  return (
      <>            
       {
          <h5
            style={{                
                padding: '0.2rem',
                marginTop: '1rem',
                textAlign: 'center',
                fontWeight: 'bold',
            }}                
          >
            {`Which sector(s) are you intersted in?`}              
        </h5>
       }
       <hr/> 
       <div className="card-body">                    
                  <div className="form-group">
                    <label for="exampleInputEmail1">Start typing and choose from the list of industry keywords below: </label>
                    <input                       
                      className="form-control" 
                      id="exampleInputEmail1" 
                      aria-describedby="emailHelp" 
                      placeholder="e.g. the Printing industry"
                      type="text"              
                      data-field={obj1.name}
                      aria-label={obj1.ariaLabel}
                      name={obj1.name}
                      value={fieldObj[obj1.name]}
                      onChange={(e) => onChange(e)}
                      />
                  </div>
                  <p 
                     style={{                                            
                      marginTop: '1rem',
                      marginTop: '1rem',                      
                  }} 
                    className="card-text text-muted">
                      {/* Company locations:  */}
                      You can select multiple options.
                  </p>          
            </div>            
                <ul className="list-group list-group-flush">
                  <>
                  {optionList.length > 0 ? <>                    
                    {optionList.map((option, index) => (
                    <>                        
                        <li                                      
                            key={index}
                            className="list-group-item highlight"
                            onClick={(e) => addTag(e, option)}
                          >                                                      
                            {option}                                                         
                          </li>
                    </>                    
                ))}
                </> : <></>
                }   
                  </>            
                </ul> 
      </>
)};

export const SelectOtherKeywords = ({}) => {  
  // use this to filter for any other keywords the user would like to use
return (
    <>            
      {<h4 style={{                
                padding: '0.2rem',
                marginTop: '1rem',                  
                marginBottom: '3rem',
                textAlign: 'center',
                fontWeight: 'bold',
            }}
        >            
            {`Are we missing anything?`}
        </h4>}        
           <p 
              style={{                                            
              marginTop: '1rem',
              marginTop: '1rem',                      
          }} 
            className="card-text text-muted">
              {/* Company locations:  */}
              You can select multiple options.
          </p>
        <ul className="list-group list-group-flush">                
            <li className="list-group-item highlight">Public companies</li>
            <li className="list-group-item highlight">Private companies</li>
        </ul>       
    </>
)};

export const SelectStockExchange = ({}) => {    
  // use this to filter what exchange user want company to be listed on 
return (
    <>            
      {<h4 style={{                
                padding: '0.2rem',
                marginTop: '1rem',                  
                marginBottom: '3rem',
                textAlign: 'center',
                fontWeight: 'bold',
            }}
        >            
            {`Choose a company size`}
        </h4>}        
           <p 
              style={{                                            
              marginTop: '1rem',
              marginTop: '1rem',                      
          }} 
            className="card-text text-muted">
              {/* Company locations:  */}
              You can select multiple options.
          </p>
        <ul className="list-group list-group-flush">                
            <li className="list-group-item highlight">Public companies</li>
            <li className="list-group-item highlight">Private companies</li>
        </ul>       
    </>
)};

// const useReduxState = () => {
//   return useSelector(
//     (state) => ({          
//       // user pref states
//       companyType: state.companyType,
//       companyLocation: state.companyLocation,        
//       companySize: state.companySize,
//       sector: state.sector,
//       industry: state.industry,
//       subIndustry: state.subIndustry,
//       operationType: state.operationType,
//       // other states
//       loadingTypes: state.loadingTypes,
//       loginTime: state.loginTime,
//     }),
//     shallowEqual
//   )};    
// const { loadingTypes, loginTime, sector } = useReduxState();
// const dispatch = useDispatch(); 

// =====================================================================================================================================================================

export const UserSelectedOptions = ({}) => {
  const useReduxState = () => {
    return useSelector(
      (state) => ({          
        // user pref states
        companyType: state.companyType,
        companyLocation: state.companyLocation,
        companySize: state.companySize,
        staffSize: state.staffSize,
        revenueSize: state.revenueSize,            
        sector: state.sector,
        industry: state.industry,
        subIndustry: state.subIndustry,
        operationType: state.operationType,
        // other states
        loadingTypes: state.loadingTypes,
        loginTime: state.loginTime,
      }),
      shallowEqual
    )};    
  const { 
    loadingTypes, loginTime, 
    companyType, companyLocation, companySize, sector, 
    industry, subIndustry, operationType,
    staffSize, revenueSize,
  } = useReduxState();
  const dispatch = useDispatch();  
  // const options = [`Public companies`, `Private companies`, `Long-established companies`, `Recently founded companies`]; 
  const tagDisplayName = {
    // company type
    "Public companies": `public`,
    "Private companies": `private`,
    "Long-established companies": `established`,
    "Recently founded companies": `new`,
    // company location
  };       
  const funcName = `UserSelectedOptions`;
    
  useEffect(() => {                            
    console.log('mounted UserSelectedOptions', companyType, companyLocation, companySize, sector, 
    industry, subIndustry, operationType);
  }, [companyType, companyLocation, companySize, sector, 
    industry, subIndustry, operationType]);

    const numberWithCommas = (x) => {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

  // const removeTag = (e, tagToRemove) => {
  //   // get category of the tag just rmeoved e.g. companyLocation, companyType, etc    
  //   const selectCategory = e.currentTarget
  //     ? e.currentTarget.getAttribute('data-object')
  //     : e.target['data-object']          
  //   console.log('mounted UserSelectedOptions selectCategory', selectCategory, tagToRemove);        
  //   // get current state for tag name from redux    
  //   let currStateInRedux = [...userPref[selectCategory]];    
  //   if(isEmpty(currStateInRedux) || !Array.isArray(currStateInRedux)) return false; // nothing to remove          
  //   // filter out the tag just removed, expecting an array of tags    
  //   // filter out value from currStateInRedux        
  //   // update state in redux 
  //   // component is rerendered as userPref is updated in nredux
  //   let removedTasgArr = currStateInRedux.filter(item => item !== tagToRemove);
  //   console.log('mounted UserSelectedOptions currStateInRedux', currStateInRedux, tagToRemove, removedTasgArr);
  //   let updateObj = {[selectCategory]: removedTasgArr};    
  //   let newUserPrefObj = Object.assign(userPref, updateObj);                
  //   dispatch(updateUserPref(newUserPrefObj));                
  // }; 
  
  const removeTag = (e, tagToRemove) => {    
    const selectCategory = e.currentTarget
      ? e.currentTarget.getAttribute('data-object')
      : e.target['data-object']          
      console.log('mounted UserSelectedOptions selectCategory', selectCategory, tagToRemove);
      // need to find the correct array in redux and remove tag
    if(selectCategory === `companyType`) {
      if(isEmpty(companyType) || !Array.isArray(companyType)) return false; // nothing to remove      
      // make copy of current state in redux 
      let currentStateArrayRedux = [...companyType];      
      // find all elements not equal to tagToRemove
      let removedTasgArr = currentStateArrayRedux.filter(item => item !== tagToRemove);      
      console.log('removedTasgArr', removedTasgArr);
      dispatch(removeCompanyType(removedTasgArr));
    }
    if(selectCategory === `companyLocation`) {
      if(isEmpty(companyLocation) || !Array.isArray(companyLocation)) return false;
      let currentStateArrayRedux = [...companyLocation];
      let removedTasgArr = currentStateArrayRedux.filter(item => item !== tagToRemove);
      console.log('removedTasgArr', removedTasgArr);
      dispatch(removeCompanyLocation(removedTasgArr, funcName));            
    }    
    if(selectCategory === `sector`) {
      if(isEmpty(sector) || !Array.isArray(sector)) return false;
      let currentStateArrayRedux = [...sector];
      let removedTasgArr = currentStateArrayRedux.filter(item => item !== tagToRemove);
      console.log('removedTasgArr', removedTasgArr);      
      dispatch(removeCompanySector(removedTasgArr, funcName));            
    }         
    
    // industry, subIndustry, operationType
    // removeCompanyLocation,
    // removeCompanySize,
    // removeCompanySector,
    // removeCompanyIndustry,
    // removeCompanySubIndustry,
    // removeCompanyOperationType,
        
  }; 

  return (
      <>         
          <table 
                id='mobileviewtable1'
                style={{
                  color: "#fff",      
                  marginTop: '1rem'                
                }}                             
                className="table">                
                <tbody>                                                    

              {<tr
                     style={{
                        background: 'var(--light)',
                        color: 'var(--dark)',
                      }}
                    >
                    <th scope="row">                    
                    <td>{`Company type:`}</td>
                    </th>        
                    <td>
                         {
                          !isEmpty(companyType) ?
                            <>
                              {companyType.map(selectedOption => <>
                                <span                         
                                style={{
                                      cursor: 'pointer',
                                      // backgroundColor: 'teal',
                                      // color: 'white'
                                  }}
                                id="companyType"
                                className="tagit"                        
                                data-object={"companyType"}      
                                // the value passed in to removeTag wil be taken from redux 
                                // the full value name as is will go in here
                                onClick={(e) => removeTag(e, selectedOption)}
                                // leanring point the text in this span cannot be too long
                                // otherwise "x" disappears in the UI
                                // e.g. public company = too long
                                // we need to shorten this with: tagDisplayName[selectedOption]
                             >
                                {tagDisplayName[selectedOption]}
                                <span                                   
                                  style={{
                                      marginLeft: '0.5rem',
                                      cursor: 'pointer',
                                      fontWeight: 'bolder',
                                }}                                
                              >x</span>
                            </span>                                    
                              </>)}
                            </> : <></>
                        }                                                                       
                    </td>                        
                  </tr>}               

                  {<tr
                     style={{
                        background: 'var(--light)',
                        color: 'var(--dark)',
                      }}
                    >
                    <th scope="row">                    
                    <td>{`Industry types:`}</td>
                    </th>        
                    <td>
                         {
                          !isEmpty(sector) ?
                            <>
                              {sector.map(selectedOption => <>
                                <span                         
                                style={{
                                      cursor: 'pointer',
                                      // backgroundColor: 'teal',
                                      // color: 'white'
                                  }}
                                id="sector"
                                className="tagit"                        
                                data-object={"sector"}
                                // the value passed in to removeTag wil be taken from redux 
                                // the full value name as is will go in here
                                onClick={(e) => removeTag(e, selectedOption)}
                                // leanring point the text in this span cannot be too long
                                // otherwise "x" disappears in the UI
                                // e.g. public company = too long
                                // we need to shorten this with: tagDisplayName[selectedOption]
                             >
                                {/* {tagDisplayName[selectedOption]} */}
                                {selectedOption}
                                <span                                   
                                  style={{
                                      marginLeft: '0.5rem',
                                      cursor: 'pointer',
                                      fontWeight: 'bolder',
                                }}                                
                              >x</span>
                            </span>                                    
                              </>)}
                            </> : <></>
                        }                                                                       
                    </td>                        
                  </tr>}

                  {<tr
                     style={{
                        background: 'var(--light)',
                        color: 'var(--dark)',
                      }}
                    >
                    <th scope="row">                    
                    <td>{`Company location:`}</td>
                    </th>        
                    <td>
                         {
                          !isEmpty(companyLocation) ?
                            <>
                              {companyLocation.map(selectedOption => <>
                                <span                         
                                style={{
                                      cursor: 'pointer',
                                      // backgroundColor: 'teal',
                                      // color: 'white'
                                  }}
                                id="companyLocation"
                                className="tagit"                        
                                data-object={"companyLocation"}      
                                // the value passed in to removeTag wil be taken from redux 
                                // the full value name as is will go in here
                                onClick={(e) => removeTag(e, selectedOption)}
                                // leanring point the text in this span cannot be too long
                                // otherwise "x" disappears in the UI
                                // e.g. public company = too long
                                // we need to shorten this with: tagDisplayName[selectedOption]
                             >
                                {/* {tagDisplayName[selectedOption]} */}
                                {selectedOption}
                                <span                                   
                                  style={{
                                      marginLeft: '0.5rem',
                                      cursor: 'pointer',
                                      fontWeight: 'bolder',
                                }}                                
                              >x</span>
                            </span>                                    
                              </>)}
                            </> : <></>
                        }                                                                       
                    </td>                        
                  </tr>}

                    <tr
                      style={{
                        background: 'var(--light)',
                        color: 'var(--dark)',
                      }}
                    >
                        <th 
                        scope="row"                          
                        >                    
                        <td>Number of employees:</td>
                        </th>                                                                                
                        <td>{numberWithCommas(staffSize)}</td>
                    </tr>                    

                    <tr
                    style={{
                      background: 'var(--light)',
                      color: 'var(--dark)',
                    }}
                    >
                        <th 
                        scope="row"                          
                        >                    
                        <td>Revenue in the region of:</td>
                        </th>                                                        
                        {/* <td>$124 million</td>                         */}
                        <td>${numberWithCommas(revenueSize)}</td>
                    </tr>  
                    <tr
                      style={{
                        background: 'var(--light)',
                        color: 'var(--dark)',
                      }}
                    >
                        <th 
                        scope="row"                          
                        >                    
                        <td>Operation keywords:</td>
                        </th>                                                        
                        <td>{`Director`}</td>
                    </tr>                                       
                    <tr
                      style={{
                        background: 'var(--light)',
                        color: 'var(--dark)',
                      }}
                    >
                        <th 
                        scope="row"                          
                        >                    
                        <td>Other keywords:</td>
                        </th>                                                        
                        <td>{`Director`}</td>
                    </tr>  
                    <tr
                      style={{
                        background: 'var(--light)',
                        color: 'var(--dark)',
                      }}
                    >
                        <th 
                        scope="row"                          
                        >                    
                        <td>Company names:</td>
                        </th>                                                        
                        <td>{`Director`}</td>
                    </tr>                              
                      </tbody>
            </table>           
      </>
)};

// =====================================================================================================================================================================

export const UserPreferenceContent = ({}) => {
  // redux flow for UserPreferenceContent    
  // use a single object to store all data for all keys
  // each step is used as the key for userPref obj in redux 
  // the individual select componennt does not need to listen for redux channges  
  // the only component that needs to listen for updates in redux is the table shown at the top of UserPreference 
  // when userPref is updated by one of the Select components we will rerender the following components:
  // UserPreferenceContent and UserSelectedOptions (do we need to explicitly render UserSelectedOptions???)
  // const useReduxState = () => {
  //   return useSelector(
  //     (state) => ({
  //       userPref: state.userPref,
  //       loadingTypes: state.loadingTypes,
  //       loginTime: state.loginTime,
  //     }),
  //     shallowEqual
  //   )};    
  // const { loadingTypes, loginTime, userPref } = useReduxState();  
  // let {companyType} = userPref;

  const dispatch = useDispatch();     
  const steps = [`companyType`, `sector`, `location`,  `companySize`, 
    // `industry`, `subIndustry`, `operationType`
  ];
  const totalSteps = steps.length;
  const [currentStep, setStep] = useState(1);
  useEffect(() => {                            
    // console.log('mounted UserPreferenceContent', currentStep, steps[currentStep-1], userPref);
    // console.log('destructure userPref', companyType);
  }, [currentStep]);  
  
  const nextStep = (e) => {    
    // if step === a certian number show a different plane  
    // index 0 === step 1    
    e.preventDefault();
    // save selected options to redux and persist:
    // increment steps 
    setStep(prevState => {      
      if(currentStep === totalSteps) {
        return prevState;
      }
      return prevState+1;
    });
    // learning points:
    // window scrollToTop does not scroll to top for current element 
    // window.scrollTo(0,0);
  };  

  const previousStep = (e) => {    
    e.preventDefault();    
    // decrement steps 
    setStep(prevState => {      
      if(currentStep === 1) {
        return prevState;
      }
      return prevState-1;
    });
  }; 
   
  const completedUserPrefFlow = (e) => { 
    // update redux user has completed new user flow 
    // wait 10 mins before making POST request to server or on logout whichever is earlier    
    e.preventDefault();    
    localStorage.removeItem("isNewUser");
  };

  return (
    <>  
      <div className="card company-card">                                   
          {
            // only show this when user has at least selected some options ie when !isEmpty(userPref)
            // const steps = [`companyType`, `location`, `companySize`, `sector`, `industry`, `sub-industry`, `operationType`];
            <UserSelectedOptions/>
          }                       
            {
              // for cards that are too long and the Next and Back buttons are all the way down at the bottom
              steps[currentStep-1] === 'location' || 
              steps[currentStep-1] === 'sector'
              ?
            <>
             {
              <div 
              style={{
                marginTop: '2rem', 
                marginBottom: '2rem',                
              }}
              className="d-flex flex-row-reverse"
          >           
            {currentStep == totalSteps ?
              <>
                <a                     
                className="btn btn-outline-secondary btn-sm"
                onClick={(e) => {
                  completedUserPrefFlow(e);
                }}
              >
                Done
              </a>
              </> : <>
              <a                     
                className="btn btn-outline-secondary btn-sm"
                onClick={(e) => {
                  nextStep(e);
                }}
              >
                Next
              </a>              
              </>
            }     
             <a     
               style={{                
                  marginRight: '1rem',                                    
                }}                
                className="btn btn-light btn-sm"
                onClick={(e) => {
                  previousStep(e);
                }}
              >
                Back
              </a>               
      </div>   
          }  
            </> : <></>
          }
                               
          {steps[currentStep-1] === 'companyType' ?
            <><SelectCompanyType/></> : <></>
          }          
          {steps[currentStep-1] === 'location' ?
            <><SelectLocation/></> : <></>
          }
          {steps[currentStep-1] === 'companySize' ?
            <><SelectCompanySize/></> : <></>
          }
          {steps[currentStep-1] === 'sector' ?
            <><SelectSector/></> : <></>
          }          
          {/* <p className="card-text">With supporting text below as a natural lead-in to additional content.</p> */}
          <div 
              style={{
                marginTop: '2rem', 
                marginBottom: '2rem',                
              }}
              className="d-flex flex-row-reverse"
          >           
            {currentStep == totalSteps ?
              <>
                <a                     
                className="btn btn-outline-secondary btn-lg"
                onClick={(e) => {
                  completedUserPrefFlow(e);
                }}
              >
                Done
              </a>
              </> : <>
              <a                     
                className="btn btn-outline-secondary btn-lg"
                onClick={(e) => {
                  nextStep(e);
                }}
              >
                Next
              </a>              
              </>
            }     
             <a     
               style={{                
                  marginRight: '1rem',                                    
                }}                
                className="btn btn-light btn-lg"
                onClick={(e) => {
                  previousStep(e);
                }}
              >
                Back
              </a>               
      </div>                      
          </div>
    </>
)}; 


// =====================================================================================================================================================================
// card layout and other components that do not require customisation
// this includes the dark overlay backdrop
// the same card layout is ued for: profile, user preference and company card
// price cards will be a smaller set of cards 

export const OverlayWrapper = ({children}) => {  
  useEffect(() => {             
    console.log('OverlayWrapper');            
  }, []);  
  return <>                            
    <div id="overlay">                           
      <div id="overlayclick"/>   
        {children}
    </div>
  </>
};  

export const CardModalLayout = ({children}) => {  
  return (
      <>            
        <OverlayWrapper>
          <div 
            style={{                                      
                justifyContent: 'center',      
                marginTop: '5rem',                                            
                marginBottom: '30rem'            
            }}
            className="row"
          >              
              {children}                          
          </div>
       </OverlayWrapper>
      </>
)}; 

// =====================================================================================================================================================================
// putting the different components together to formulate the final components

// =====================================================================================================================================================================
// add stripeCheckout button at the footer of the card

export const PriceCard = ({}) => {
  const [cards, setCards] = useState([]);
  const pricing_list = COMPONENT_STATIC_DATA.pricingCardsProps;
  const mode = `checkout`;  
    useEffect(() => {    
      console.log('mounted pricing_list', pricing_list);      
      if(!isEmpty(pricing_list)) {
        setCards(pricing_list.slice(1, pricing_list.length));
      }    
    console.log(`pricing_list`, pricing_list);
  }, []);  
  return (
      <>                  
      <CardModalLayout>    
        <div className="d-flex flex-column align-items-center">
          {cards.length > 0 ? 
            cards.map((cardData, index) => (
              <>
                <div key={index}>
                  <PriceCardContent cardData={cardData}/>
                </div>
              </>
            )) : <></>
          }          
        </div>
       </CardModalLayout>
      </>
)};

// =====================================================================================================================================================================
// import CompanyCard into DashboardSearch

export const CompanyCard = ({companyCardList}) => {
  useEffect(() => {    
    console.log('mounted CompanyCard', companyCardList);    
  }, [companyCardList]);
  return (
      <>            
        <CardModalLayout>       
        {companyCardList.length > 0 ? 
            companyCardList.map((cardData, index) => (
              <>
                <div key={index}>
                  <CompanyCardContent cardData={cardData}/>
                </div>
              </>
            )) : <></>
          }                    
       </CardModalLayout>
      </>
)};

export const ProfileCard = ({}) => {
  return (
      <>         
        <CardModalLayout>               
          <ProfileCardContent/>
       </CardModalLayout>
      </>
)};

export const UserPreferenceCard = ({}) => {  
  //  const useReduxState = () => {
  //   return useSelector(
  //     (state) => ({
  //       // leanring points:
  //       // do not use nested objects to represent states in redux 
  //       // companyType: state.userPref.companyType, // works for the first change 
  //       companyType: state.companyType,
  //       location: state.location,
  //       companySize: state.companySize,
  //       sector: state.sector,
  //       industry: state.industry,
  //       subIndustry: state.subIndustry,
  //       operationType: state.operationType,        
  //       loadingTypes: state.loadingTypes,
  //       loginTime: state.loginTime,
  //     }),
  //     shallowEqual
  //   )};    
  // const { loadingTypes, loginTime, companyType } = useReduxState();    
  // const dispatch = useDispatch();       
  // useEffect(() => {                            
  //   console.log('mounted UserPreferenceCard', companyType);
  // }, [companyType]);    
  return (
      <>            
        <CardModalLayout>       
          <UserPreferenceContent/>         
       </CardModalLayout>
      </>
)};

// =====================================================================================================================================================================
// DashboardModals will be imported into AuthButtons
// everytime a user clicks a button it will update the showWhichModal state in AuthButtons
// the showWhichModal is then passed down to DashboardModals
// we have to ensure the user is logged in before they can open these modals 

export default function DashboardModals({
    modalToggle, 
    modalType,
}) {      
  // listen for when user has completed new user flow
  // completedUserPrefFlow
  const useReduxState = () => {
    return useSelector(
      (state) => ({        
        loginTime: state.loginTime,
        profile: state.profile,
        uuid: state.uuid,
      }),
      shallowEqual
    );
  };    
  const { loginTime, profile, uuid } = useReduxState();
  const dispatch = useDispatch();     
  // what actions will OPEN the modal?
  // we need toggle for openning different types 
  // what if user clicks same buttonn 
  // when a user switches between modals with showWhichModal
  // they click button and showWhichModal updates 
  // what actions will CLOSE the modal?
  // when user clicks "Done" in UserPref UI so we need to listen for redux 
  // we should also listen to redux for when user
  const [openFilterModal, setOpenModal] = useState(true); // for tetsing set to true
  const [modalToggle2, setModalToggle2] = useState(true); // for tetsing set to true
  
  // removeNewUser();
  // console.log('DashboardModals isNewUser', isNewUser);    
  // checkIfNew()
  // const removeNewUser = () => {
  //   localStorage.removeItem("isNewUser");
  // }; 
  // const isNewUser = localStorage.getItem('isNewUser') ? JSON.parse(localStorage.getItem('isNewUser')) : false;  
  // const checkIfNew = () => {    
  //   if(isNewUser) {
  //     setOpenModal(true);
  //   }
  // }; 
  // localStorage.setItem(`isNewUser`, JSON.stringify(true));  // testing
  
  useEffect(() => {    
    console.log('mounted DashboardModals', loginTime, profile, uuid);
    console.log('mounted DashboardModals2', modalToggle, modalType, modalToggle2);  
    // overlay should be detectable inside each of the card components 
    const overlayBackdrop = document.getElementById("overlayclick");
    if(overlayBackdrop) {
      overlayBackdrop.addEventListener('click', (event) => {            
            setOpenModal(false);
            console.log('overlayBackdrop.addEventListener');
        })  
    }    
    if(modalToggle !== modalToggle2) {
      let newModalToggle = modalToggle;
      setModalToggle2(newModalToggle)
      setOpenModal(true);
    }        
    // the UserPreferenceCard component will openn if user isNewUser = true
    // add check redux profile here                  
    // remove isNewUser here and when user has finished the complete user pref flow and clicked done                             
  }, [loginTime, profile, modalToggle, modalType, openFilterModal]);   
  const closeModal = () => {
    setOpenModal(false);
  }     
      return <>           
      {/* <div 
        id="overlayclick"
        onClick={(e) => closeModal(e)}
      />    */}
      {modalType === 'paymentPlans' ? <>
        {
            openFilterModal ? <>
              <PriceCard/>
            </> : <></>
          }                
      </> : <></>}
      {modalType === 'userPref' ? <>
        {
          openFilterModal ? <>
            <UserPreferenceCard cardName={``}/>
          </> : <></>
        }        
      </> : <></>}
      {modalType === 'profileModal' ? <>
      {
          openFilterModal ? <>
            <ProfileCard cardName={{cardName: `Profile Card`}}/>
          </> : <></>
        }                
      </> : <></>}              
      {/* <CompanyCard cardName={{cardName: `Company Card`}}/>                         */}
      </>
};  


//   <div 
//   style={{                                      
//       justifyContent: 'center',                  
//   }}
//   className="row"
// >                      
// <hr/>
// {
//     <div style={{
//       display: 'flex',
//       justifyContent: 'center',
//       marginTop: '1rem',
//       width: '100%'                                
//     }}
//   //   className="row"
//     >
//     <input 
//       style={{
//           width: '30%',
//           marginBottom: '2rem'
//           // margin: '0.5rem'
//         }}
//       className="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search"/>
//       {/* <button className="btn btn-outline-success my-2 my-sm-0" type="submit">Search</button> */}
//     </div>
//   }            
//   <div
//       style={{
//           width: '18rem'
//       }} 
//       className="card"         
//   >
//       <img className="card-img-top" src="..." alt="Card image cap"/>
//       <div className="card-body">
//           <h5 className="card-title">Card title</h5>
//           <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
//       </div>
//       <ul className="list-group list-group-flush">
//           <li className="list-group-item">Cras justo odio</li>
//           <li className="list-group-item">Dapibus ac facilisis in</li>
//           <li className="list-group-item">Vestibulum at eros</li>
//       </ul>
//       <div className="card-body">
//           <a href="#" className="card-link">Card link</a>
//           <a href="#" className="card-link">Another link</a>
//       </div>
//   </div>
//   <div
//       style={{
//           width: '18rem'
//       }} 
//       className="card"         
//   >
//       <img className="card-img-top" src="..." alt="Card image cap"/>
//       <div className="card-body">
//           <h5 className="card-title">Card title</h5>
//           <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
//       </div>
//       <ul className="list-group list-group-flush">
//           <li className="list-group-item">Cras justo odio</li>
//           <li className="list-group-item">Dapibus ac facilisis in</li>
//           <li className="list-group-item">Vestibulum at eros</li>
//       </ul>
//       <div className="card-body">
//           <a href="#" className="card-link">Card link</a>
//           <a href="#" className="card-link">Another link</a>
//       </div>
//   </div>
// </div>