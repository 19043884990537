import { ACTION_TYPES } from './actionTypes';
// learning points:
// cannot use dispatch here: TypeError: Cannot read properties of null (reading 'useContext')
// import { useDispatch } from 'react-redux';
// const dispatch = useDispatch();

// ================================================================================================================================================================================
// flow for creating new redux actions: 

export const updateUserPref = (userPrefObj, loadingType) => ({
  // loadingType = the function name 
  type: ACTION_TYPES.SET_USER_PREF,
  payload: userPrefObj,
  meta: {
    label: loadingType, 
  }
}); 

// ================================================================================================================================================================================

export const addCompanyType = (dataArr, loadingType) => ({
  // loadingType = the function name 
  type: ACTION_TYPES.ADD_COMPANY_TYPE,
  payload: dataArr,
  meta: {
    label: loadingType, 
  }
});

export const addCompanyLocation = (dataArr, loadingType) => ({  
  type: ACTION_TYPES.ADD_COMPANY_LOCATION,
  payload: dataArr,
  meta: {
    label: loadingType, 
  }
});

export const addCompanySize = (dataArr, loadingType) => ({  
  type: ACTION_TYPES.ADD_COMPANY_SIZE,
  payload: dataArr,
  meta: {
    label: loadingType, 
  }
});

export const addCompanyRevenueSize = (data, loadingType) => ({  
  type: ACTION_TYPES.ADD_COMPANY_REV_SIZE,
  payload: data,
  meta: {
    label: loadingType, 
  }
});

export const addCompanyStaffSize = (data, loadingType) => ({  
  type: ACTION_TYPES.ADD_COMPANY_STAFF_SIZE,
  payload: data,
  meta: {
    label: loadingType, 
  }
});

export const addCompanySector = (dataArr, loadingType) => ({  
  type: ACTION_TYPES.ADD_COMPANY_SECTOR,
  payload: dataArr,
  meta: {
    label: loadingType, 
  }
});

export const addCompanyIndustry = (dataArr, loadingType) => ({  
  type: ACTION_TYPES.ADD_COMPANY_INDUSTRY,
  payload: dataArr,
  meta: {
    label: loadingType, 
  }
});

export const addCompanySubIndustry = (dataArr, loadingType) => ({  
  type: ACTION_TYPES.ADD_COMPANY_SUB_INDUSTRY,
  payload: dataArr,
  meta: {
    label: loadingType, 
  }
});              

export const addCompanyOperationType = (dataArr, loadingType) => ({  
  type: ACTION_TYPES.ADD_COMPANY_OPERATION_TYPE,
  payload: dataArr,
  meta: {
    label: loadingType, 
  }
});

// ================================================================================================================================================================================

export const removeCompanyType = (dataArr, loadingType) => ({
  // loadingType = the function name 
  type: ACTION_TYPES.REMOVE_COMPANY_TYPE,
  payload: dataArr,
  meta: {
    label: loadingType, 
  }
});

export const removeCompanyLocation = (dataArr, loadingType) => ({  
  type: ACTION_TYPES.REMOVE_COMPANY_LOCATION,
  payload: dataArr,
  meta: {
    label: loadingType, 
  }
});

export const removeCompanySize = (dataArr, loadingType) => ({  
  type: ACTION_TYPES.REMOVE_COMPANY_SIZE,
  payload: dataArr,
  meta: {
    label: loadingType, 
  }
});

export const removeCompanySector = (dataArr, loadingType) => ({  
  type: ACTION_TYPES.REMOVE_COMPANY_SECTOR,
  payload: dataArr,
  meta: {
    label: loadingType, 
  }
});

export const removeCompanyIndustry = (dataArr, loadingType) => ({  
  type: ACTION_TYPES.REMOVE_COMPANY_INDUSTRY,
  payload: dataArr,
  meta: {
    label: loadingType, 
  }
});

export const removeCompanySubIndustry = (dataArr, loadingType) => ({  
  type: ACTION_TYPES.REMOVE_COMPANY_SUB_INDUSTRY,
  payload: dataArr,
  meta: {
    label: loadingType, 
  }
});          

export const removeCompanyOperationType = (dataArr, loadingType) => ({  
  type: ACTION_TYPES.REMOVE_COMPANY_OPERATION_TYPE,
  payload: dataArr,
  meta: {
    label: loadingType, 
  }
});

// ================================================================================================================================================================================
// global actions

// ================================================================================================================================================================================

export const startLoading = (loadingType) => ({
  type: ACTION_TYPES.START_LOADING,
  payload: true,
  meta: {
    label: loadingType, 
  }
}); 

export const finishLoading = (loadingType) => ({
  type: ACTION_TYPES.FINISHED_LOADING,
  payload: false,
  meta: {
    label: loadingType, 
  }
}); 

export const startPageLoading = () => ({
  type: ACTION_TYPES.START_PAGE_LOADING,
  payload: true,
  meta: {
    label: `startPageLoading`, 
  }
}); 

export const finishPageLoading = () => ({
  type: ACTION_TYPES.FINISHED_PAGE_LOADING,
  payload: false,
  meta: {
    label: `finishPageLoading`, 
  }
}); 

export const openCustomModal = (modalType, message) => ({
  // the modal component will be included in index.js in pages so it can be used for entire app
  // use boolean to show modal by listening to redux 
  // default component state will be false so modal no show
  type: ACTION_TYPES.OPEN_CUSTOM_MODAL,
  payload: {
    isOpen: true,
    displayMessage: message 
  },
  meta: {
    label: modalType, 
  }
}); 

export const closeProfileModal = (modalType) => ({
  // logic flow for opening and closing modals: 
  // user clicks a button to open modal 
  // the buton changes the state within the modal 
  // the modal also listens for state update of redux 

  // profile modal will open based on 2 cases: 
  // 1. 
  // 2.   
  type: ACTION_TYPES.CLOSE_PROFILE_MODAL,
  payload: true,
  meta: {
    label: modalType, 
  }
}); 

export const closePricingModal = (modalType) => ({
  // pricing modal will open based on 2 cases: 
  // 1. 
  // 2. 
  type: ACTION_TYPES.CLOSE_PRICING_MODAL,
  payload: true,
  meta: {
    label: modalType, 
  }
}); 

export const closeAllModals = (modalType) => ({
  type: ACTION_TYPES.CLOSE_ALL_MODALS,
  payload: true,
  meta: {
    label: modalType, 
  }
}); 

// ================================================================================================================================================================================

export const replenishEverything = data => ({
  type: ACTION_TYPES.REPLENISH_REDUX, 
  error: false,
  payload: data,
  meta: {
    label: 'replenishEverything', 
  }
});

export const getDataAndReplenish = () => ({
  // dispatch this action whenever the app starts or refreshes so put inside <User/>
  // checks local storage and replenishes reducers 
  // learning points: 
  // it is easier to replenish when there is only a single reducer 
  type: ACTION_TYPES.PERSIST, 
  error: false,
  payload: Date.now(),
  meta: {
    label: 'replenish', 
  }
});

export const persistProfile = profileObj => ({
  type: ACTION_TYPES.PERSIST, 
  error: false,
  payload: profileObj,
  meta: {
    label: 'persistProfile', 
  }
});

export const onRegisterSuccess = profileObj => ({
  type: ACTION_TYPES.REGISTER_SUCCESS, 
  error: false,
  payload: profileObj,
  meta: {
    label: 'registerSuccess', 
  }
});

export const onLoginSuccess = profileObj => ({  
  type: ACTION_TYPES.LOGIN_SUCCESS,  
  error: false,
  // payload contains: payload.isNewUser
  payload: profileObj, 
  meta: {
    label: 'loginSuccess', 
  }
});

export const onLogout = (loggedOutAt, rememberLastUrlPath) => ({
  type: ACTION_TYPES.LOGOUT,  
  error: false,
  payload: {
    isLogout: true,
    loggedOutAt: loggedOutAt,
    rememberLastUrlPath: rememberLastUrlPath
  }, 
  meta: {
    label: 'logout', 
  }
});

// ================================================================================================================================================================================

export const updateProfile = profileObj => ({
  type: ACTION_TYPES.UPDATE_PROFILE,  
  error: false,
  payload: profileObj,
  meta: {
    label: 'updateProfile', 
  }
});

// ================================================================================================================================================================================

export const response401 = (timeStamp, pathName) => {
  try {    
    console.log(`execute response401`, timeStamp, pathName);
    // return dispatch(onLogout(timeStamp, pathName));
  } catch (err) {
    console.error(`log errs response401`, err);
  }
}; 

// ================================================================================================================================================================================

export const makePayment = (paymentObj) => ({
  type: ACTION_TYPES.API,
  error: false,
  payload: paymentObj,
  meta: { label: 'makePayment'}
});

