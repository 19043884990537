import React, { useEffect } from 'react';
import logo from './RU.PNG';

export const CompanyIcon = ({}) => {  
    return (
      <>       
         <div
              style={{
                // margin: '0 auto',
                marginTop: '5rem',              
              }}
            >
            <span>
            <img
                key={`business-icon-img`}
                className="business-icon-img"                 
                src={logo}
                width="50"
                height="50"
                alt="business icon"
                />  
              </span>
            </div>          
      </>
)}; 

export default function AboutUs({}) {
  useEffect(() => {
    console.log(`mounted AboutUs`);
  },[]); 
  return (
      <>               
        {/* <div 
        style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '3rem',    
                }}
        >
            <CompanyIcon/>
        </div> */}
         <article className="about-us">                            
                <h2
                style={{
                    color: 'indigo',
                    textAlign: 'center',                    
                    marginBottom: '3rem',
                    marginTop: '2rem',                    
                }}
                >
                  About Company Repo
                </h2>                
                <p>
                    {/* {`
                        We live by one principle and that is to make the time spent on the homework phase of investing in great companies shorter. 
                    `}                                         */}
                    {`
                        We live by one principle and that is to make the time spent on doing homework on companies shorter.
                    `}                                        
                </p>
                <p>
                    {`
                        At the core of our mission, we aim to provide a starting point where 
                        users can discover new companies and develop the story behind each of these 
                        companies through carrying out research on our platform.                                                 
                    `}                                        
                </p>                                    
                <p>
                    {`
                        So how do people invest in great companies?
                    `}                                        
                </p>                
                <p>
                    {`  
                        Well, first these companies must be brought to your attention. 
                    `}  
                    {' '}
                    <span
                        style={{
                        color: 'indigo',                        
                        fontWeight: 'bold'
                    }}
                    >                        
                    {` 
                        Peter Lynch puts it the best when he talks about discovering new companies - 
                        discovering a new company is the lead to a story that must be developed.
                    `}  
                    </span> {' '}                    
                </p>    
                <p>
                    {`

                        This is where we want to help our users - to provide a list of 

                        And we help our users develop the story by aiding in their research of those companies. 

                        We search the internet for news articles on the companies 
                        
                    `}                                        
                </p>                               
                {/* <p>                    
                    Education alone is not enough.
                    it is important to develop habits.
                    Your should focus on taking action.                    
                    No matter how small the improvement, keep going.
                    {' '}
                    <span
                        style={{
                        color: 'indigo',                        
                        fontWeight: 'bold'
                    }}
                    >
                        Whether they are stepping stones or milestones, the key point is to keep moving forward.
                    </span> {' '}                    
                    And focus on achieving those short-term and long-term goals you have set for yourself. 
                </p>                              
                <p>                    
                    It all starts with self belief.                     
                    We want to provide a place where people can come to learn new skills without discrimination.
                    We hope you could use this place to share your learning journey with us if you wish to.                     
                </p> */}
                <p>                    
                    You can always reach out to us at 
                    {' '}
                    <span
                        style={{
                        color: 'indigo',                        
                        fontWeight: 'bold'
                    }}
                    >
                        team@companyrepo.com                        
                    </span> {' '}   
                </p>
                {/* <p>                    
                    We aim to write more motivational content in the coming year through our blog content either on this website or through our Medium blog. 
                </p> */}

                {/* <div className='d-flex column'>
                    <a        
                        style={{
                            marginBottom: '2rem'                        
                        }}                             
                        className="btn btn-warning btn-lg" href="#" role="button"
                        aria-hidden="true"
                        alt="Click to go to our Medium blog"
                        aria-label="Click to go to our Medium blog"
                        onClick={() =>
                            window.open(                                        
                                'https://medium.com/@remoteupskill',
                                "_blank"
                            )
                        }
                    >
                        Medium Blog
                    </a>
                    <a        
                        style={{
                            marginBottom: '2rem',
                            marginLeft: '1rem'
                        }}                             
                        className="btn btn-success btn-lg" href="#" role="button"
                        aria-hidden="true"
                        alt="Click to go to our Medium blog"
                        aria-label="Click to go to our Medium blog"
                        onClick={() =>
                            window.open(                                        
                                'https://remoteupskillnow.gumroad.com',
                                "_blank"
                            )
                        }
                    >
                        Books on Gumroad
                    </a>
                </div>                */}

                {/* <p>                    
                    Here are some of our more popular blogs on Medium:
                </p>      
                <ul>
                    <li 
                        style={{
                            marginBottom: '2rem'                        
                        }}   
                    >
                        <a              
                        style={{
                            color: 'blue',
                            textDecoration: 'underline',
                            cursor: 'pointer'                            
                        }}                                                         
                          onClick={() =>
                            window.open(                                        
                                'https://medium.com',
                                "_blank"
                            )
                        }
                        >
                            Article 1
                        </a>
                    </li>
                    <li
                        style={{
                            marginBottom: '2rem'                        
                        }}   
                    >       
                    <a              
                        style={{
                            color: 'blue',
                            textDecoration: 'underline',
                            cursor: 'pointer'                            
                        }}                                                         
                          onClick={() =>
                            window.open(                                        
                                'https://medium.com',
                                "_blank"
                            )
                        }
                        >
                            Article 2
                        </a>
                    </li>                    
                    <li
                        style={{
                            marginBottom: '2rem'                        
                        }}   
                    >                        
                        <a              
                        style={{
                            color: 'blue',
                            textDecoration: 'underline',
                            cursor: 'pointer'                            
                        }}                                                         
                          onClick={() =>
                            window.open(                                        
                                'https://medium.com',
                                "_blank"
                            )
                        }
                        >
                            Article 3
                        </a>                 
                    </li>                                        
                </ul>                 */}
            </article>           
      </>
)}; 