import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import isEmpty from 'lodash.isempty';
import LoginModal from './LoginModal';
import Loading from './Loading';
import DashboardModals from './DashboardModals';
import firebaseSer from './utils/services/FirebaseService';

export const AuthenticatedButtons = ({}) => {  
  // we need to add all the components opened by these buttons here: 
  const useReduxState = () => {
    return useSelector(
      (state) => ({
        loadingTypes: state.loadingTypes,
        loginTime: state.loginTime,
      }),
      shallowEqual
    )};    
  const { loadingTypes, loginTime } = useReduxState();  
  const dispatch = useDispatch();   
  const [modalType, setModalType] = useState(`userPref`);   
  const [modalToggle, setModalToggle] = useState(false);   
  const [thisLoadingType, setLoadingType] = useState([]); // on page refresh or rerender user can open LoginModal as this is null      
  const [isLoading, setIsLoading] = useState(false);
  const listeningForEvents = [`createAccountWithEmailAndPassword`, `loginWithEmailAndPassword`];
  
  useEffect(() => {    
    console.log(`log AuthButtons`, loginTime, loadingTypes, thisLoadingType, modalToggle, modalType);
    // listenForLoadingTypes(loadingTypes);
  }, [loginTime, loadingTypes, isLoading]);
  
  const logout = (e) => {    
    // start loading logic inside firebaseSer.logout
    e.preventDefault();    
    firebaseSer.logout(dispatch);
  };

  const switchModalCards = (e, modalType) => {
    // profileModal
    // userPref
    // paymentPlans
    console.log(`modalType`, modalType)
    e.preventDefault();        
    setModalType(modalType)
    setModalToggle(prevState => {  
      console.log(`previous modal toggle`, prevState);
      let newToggle = !prevState;
      return newToggle;
    });             
  };

  return (
    <>              
          {
            // the modal card components for:
            // user preferences, stripe cards, user profile 
            <DashboardModals
              modalToggle={modalToggle}
              modalType={modalType}
            />
          }
         { 
          // all buttons after login success
          // the modal card components go above these buttons in hidden mode on default state
          <div 
                style={{                                      
                  justifyContent: 'center',
                  // use these css if want buttons to line by horizontally
                  // padding: '2rem',
                  // display: 'flex',
                  // flexDirection: 'row',                  
                }}
                className="row"
          >               
                <button 
                  style={{                    
                    // use these css if want buttons to line by horizontally
                    // margin: '1rem',
                  }}
                  className="btn btn-outline-dark mb-3" 
                  type="button"                  
                  onClick={(e) => {
                    logout(e, 'login');
                  }}
                  >
                      Logout
                  </button>
                  <button 
                  style={{                    
                    // margin: '1rem',                    
                  }}
                  className="btn btn-outline-success mb-3" 
                  type="button"   
                  onClick={e => switchModalCards(e, 'profileModal')}
                  >                    
                      Profile Settings
                  </button>
                  <button 
                  style={{                    
                    // margin: '1rem',                    
                  }}
                  className="btn btn-outline-warning mb-3"                   
                  type="button"   
                  onClick={e => switchModalCards(e, 'userPref')}
                  >                    
                      User Preferences
                  </button>
                  <button 
                  style={{                    
                    // margin: '1rem',                    
                  }}
                  className="btn btn-outline-primary mb-3" 
                  type="button"   
                  onClick={e => switchModalCards(e, 'paymentPlans')}
                  >
                      Plans
                  </button>
                </div>
              }
      </>
)};  

export default function AuthButtons({ props }) {  
 const useReduxState = () => {
        return useSelector(
          (state) => ({
            loadingTypes: state.loadingTypes,
            loginTime: state.loginTime,
          }),
          shallowEqual
        );
      };    
  const { loadingTypes, loginTime } = useReduxState();  
  const dispatch = useDispatch();   
  const [modalType, setLoginModalObj] = useState(false);   
  const [thisLoadingType, setLoadingType] = useState([]); // on page refresh or rerender user can open LoginModal as this is null      
  const [isLoading, setIsLoading] = useState(false);
  const listeningForEvents = [`createAccountWithEmailAndPassword`, `loginWithEmailAndPassword`];

  useEffect(() => {    
    console.log(`log AuthButtons`, loginTime, loadingTypes, thisLoadingType);
    listenForLoadingTypes(loadingTypes);       
  }, [loginTime, loadingTypes, isLoading]);   

  const listenForLoadingTypes = (loadingTypes) => {
    // loginModal states flow
    // default is allow to be open 
    // then loading starts and redux is updated 
    // redux loadingTypes now contans either: `createAccountWithEmailAndPassword` or `loginWithEmailAndPassword` inside array
    // useEffect here picks up this update and fires findLoadingType()
    // findLoadingType() fires off setLoadingType() to include either `createAccountWithEmailAndPassword` or `loginWithEmailAndPassword`
    // thisLoadingType inside component now contains either `createAccountWithEmailAndPassword` or `loginWithEmailAndPassword`
    // when loading finishes redux is updated again removing either: `createAccountWithEmailAndPassword` or `loginWithEmailAndPassword`
    // we execute setLoadingType() again but this time check if prevState contains either: `createAccountWithEmailAndPassword` or `loginWithEmailAndPassword`
    // we execute setLoginModalObj(false) to close modal
    // and we set state to back to empty array using filter function as now we cannot find `createAccountWithEmailAndPassword` or `loginWithEmailAndPassword`
    let funcName = `listenForLoadingTypes`;
    try {                  
      setLoadingType(prevState => {   
        // learning points:
        // to access previous state no need to use notation prevState.thisLoadingType
        // prevState is already the previous state      
        // learning points:
        // yes we can fire external functions inside setState functions      
        // filter returns empty array if cannot find
        // no matter what the result of findLoadingEvent is we need to run: setLoadingType        
        let findLoadingEvent = loadingTypes.filter(x => listeningForEvents.includes(x)); // current redux
        if(!isEmpty(findLoadingEvent)) {
          // update is loading in component 
          setIsLoading(true);
        }
        let checkPrevStateToCloseModal = prevState.filter(x => listeningForEvents.includes(x)); // prevState in component 
        console.log(`test prevState`, prevState);
        console.log(`test findLoadingEvent`, findLoadingEvent);
        console.log(`test checkPrevStateToCloseModal`, checkPrevStateToCloseModal);        
        if(checkPrevStateToCloseModal.length > 0 && findLoadingEvent.length === 0) {
          // in simple terms:
          // previous array state === 1
          // current redux array state === 0
          // then we close modal
          // if checkPrevStateToCloseModal is truthy but findLoadingEvent is empty array then we close modal
          // this shows loading was started but now finished 
          console.log(`close modal`, checkPrevStateToCloseModal);        
            setLoginModalObj(false);
            // update is loading in component 
            setIsLoading(false);
        }
        let arr = [...prevState, ...findLoadingEvent];
        return arr;
      });
      return true;
    } catch (err) {      
      console.error(`err in ${funcName}: ${err}`);
    }
  };

  const logout = (e) => {
    // move logout to authenticated buttons
    e.preventDefault();    
    firebaseSer.logout(dispatch);
  };
  
  const openLoginModal = async (event, modalType) => {
    let funcName = `openLoginModal`
    try {      
      event.preventDefault();
      setLoginModalObj(modalType);      
    } catch (err) {
      console.log(`err in ${funcName}: ${err}`);
    }
  };

  const closeLoginModal = async () => {
    let funcName = `openLoginModal`
    try {      
      setLoginModalObj(false);
    } catch (err) {
      console.log(`err in ${funcName}: ${err}`);
    }
  };

  return (
    <>               
      <LoginModal
        props={modalType}
        show={modalType}
        onHide={(e) => closeLoginModal()}
      />
       <div     
            style={{                            
                height: 'fit-content',                            
                margin: '1rem',
                marginBottom: '2rem',   
                marginTop: '5rem',                 
                padding: '1rem',                        
                borderTopLeftRadius: '0.5rem',
                borderTopRightRadius: '0.5rem',
                borderBottomRightRadius: '0.5rem',
                borderBottomLeftRadius: '0.5rem',
                fontSize: '16px',                 
                display: 'flex',
                justifyContent: 'center',                
                // backgroundColor: 'green' // for development show background color
            }}                        
        >   
      {isLoading ? 
        <>
          <Loading
            loading={isLoading} 
            type={`revolvingdot`}
          />
        </> :
        <>
      {loginTime ? 
        <>
          {/* <div
          className="buttons"
          onClick={(event) => {
            logout(event, 'login');
          }}
          >
            Logout
          </div>   */}
          <AuthenticatedButtons/>
        </> : 
        <>          
          <div
          style={{
            marginRight: '1rem',
          }}
          className="buttons"
          onClick={(event) => {
            openLoginModal(event, 'signup');
          }}
        >
          Register with email 
        </div>
        <div
          style={{
            // marginTop: '0.5rem',
          }}
          className="buttons"
          onClick={(event) => {
            openLoginModal(event, 'login');
          }}
        >
          Sign in with email
        </div>                    
        </>        
      }    
        </>
      }          
      </div>  
    </>
  );
};
