import React, { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import AboutUs from './AboutUs';
import LandingHero from './LandingHero'; 
import Footer from './Footer';
import Dashboard from './Dashboard'; 

// ===========================================================================================================================================================================================
// import all static images for different components on landing page here:
import logo from './logo.svg';
import landingHeroImag1 from './bootstrap-themes.png';

// ========================================================================================================================================
// NOTES:

// ===========================================================================================================================================================================================
// other useful components for landing

const LandingServiceIntro = ({}) => {
  return (
    <>
     <div 
        style={{
          // marginTop: '4rem',
          // border: '2px solid black',
          height: '30rem',
          // learning points: 
          // gradient is not good for backgourn colur 
          // as text gets blurred
          // backgroundColor: 'var(--text-secondary)',
          // background: "url('https://source.unsplash.com/Dn4NG1fICMs') 20px 20px/ 150px 218px no-repeat, url('https://source.unsplash.com/Dn4NG1fICMs') 0% 0% / cover" // works          
          // background: "url('https://source.unsplash.com/xtgONQzGgOE') 0% 0% / cover"          
          // background: "url('https://source.unsplash.com/SVVTZtTGyaU') 0% 0% / cover"                    
          // background: "url('https://source.unsplash.com/6U-sSfBV-gM') 0% 0% / cover"                              
          // background: "url('https://source.unsplash.com/tjguVu0GoEM') 0% 0% / cover"          
          // background: "url('https://source.unsplash.com/csIgqEjWu3o') 0% 0% / cover"                    
          background: "url('https://source.unsplash.com/nxZDMUQhN4o') 0% 0% / cover"
        }}
        className="jumbotron">
        <div 
              style={{                
                color: 'var(--white)',                                
              }}
          className="container">
            {/* <h1 className="display-3">Discovering New Companies Starts Here</h1> */}
            {/* <h1 className="display-3">Developing the Story Behind Every Company</h1> */}
            <h1 className="display-3">
              {`Building a Picture from the Stories Behind Great Companies`}
            </h1>
            <h4>
              {`The market is beyond your control so search out profitable companies instead.`}            
            </h4>
            <p>
              {`
                Company Repo provides a place for discovering and researching companies around the world mainly through online news articles.
              `}
              {/* First, you need to be aware of the companies. 
              Then you have to find out the story behind the companies. */}
            </p>            
          {/* <p><a className="btn btn-primary btn-lg" href="#" role="button">Learn more &raquo;</a></p> */}
        </div>
      </div>

      <div 
      style={{
        marginTop: '4rem',
        border: '2px solid black'
      }}
      className="container">        
        <div className="row">
          <div className="col-md-4">
            <h2>Basic Profile</h2>
            <p>This is the starting point for developing a story for the companies you are interested in.</p>
            <p>This includes basic informationn about the company such as the industry, what are they operating as, the size of the company. </p>
            {/* <p><a className="btn btn-secondary" href="#" role="button">View details &raquo;</a></p> */}
          </div>
          <div className="col-md-4">
            <h2>Latest News</h2>
            <p>The latest news related to the company can range from the latest online news articles to certain performance details related to the stock market. </p>
            <p>Rather than providing real-time data, we provide a bigger picture on how a company is doing by building up a histroy of key stock market metrics for the company</p>
            {/* how the company has performed on the stock market in recent weeks.  */}
            {/* <p><a className="btn btn-secondary" href="#" role="button">View details &raquo;</a></p> */}
          </div>
          <div className="col-md-4">
            <h2>Historical Data</h2>
            <p>
              This includes historical news articles about the company to how it has performed on the stock market in the past.              
            </p>
            <p>
            The past does not dictate how a company will perform in the future but it does tell us how a company responds to external factors.
            </p>            
            {/* <p><a className="btn btn-secondary" href="#" role="button">View details &raquo;</a></p> */}
          </div>
        </div>        
      </div>
    </>
  )
}; 

const LandingCards = ({}) => {
  return (
    <>
       <div 
      style={{
        marginTop: '4rem',        
      }}
      className="row mb-2">
        <div className="col-md-6">
          <div className="card flex-md-row mb-4 box-shadow h-md-250">
            <div className="card-body d-flex flex-column align-items-start">
              <strong className="d-inline-block mb-2 text-primary">World</strong>
              <h3 className="mb-0">
                <a className="text-dark" href="#">Featured post</a>
              </h3>
              <div className="mb-1 text-muted">Nov 12</div>
              <p className="card-text mb-auto">This is a wider card with supporting text below as a natural lead-in to additional content.</p>
              <a href="#">Continue reading</a>
            </div>            
            <img style={{width: '200px', height: '300px'}} className="card-img-right flex-auto d-none d-md-block" src={logo} alt="Card image cap"/>
          </div>
        </div>
        <div className="col-md-6">
          <div className="card flex-md-row mb-4 box-shadow h-md-250">
            <div className="card-body d-flex flex-column align-items-start">
              <strong className="d-inline-block mb-2 text-success">Design</strong>
              <h3 className="mb-0">
                <a className="text-dark" href="#">Post title</a>
              </h3>
              <div className="mb-1 text-muted">Nov 11</div>
              <p className="card-text mb-auto">This is a wider card with supporting text below as a natural lead-in to additional content.</p>
              <a href="#">Continue reading</a>
            </div>
            <img style={{width: '200px', height: '300px'}} className="card-img-right flex-auto d-none d-md-block" src={logo} alt="Card image cap"/>
          </div>
        </div>
      </div>
      <div className="row mb-2">
        <div className="col-md-6">
          <div className="card flex-md-row mb-4 box-shadow h-md-250">
            <div className="card-body d-flex flex-column align-items-start">
              <strong className="d-inline-block mb-2 text-primary">World</strong>
              <h3 className="mb-0">
                <a className="text-dark" href="#">Featured post</a>
              </h3>
              <div className="mb-1 text-muted">Nov 12</div>
              <p className="card-text mb-auto">This is a wider card with supporting text below as a natural lead-in to additional content.</p>
              <a href="#">Continue reading</a>
            </div>            
            <img style={{width: '200px', height: '300px'}} className="card-img-right flex-auto d-none d-md-block" src={logo} alt="Card image cap"/>
          </div>
        </div>
        <div className="col-md-6">
          <div className="card flex-md-row mb-4 box-shadow h-md-250">
            <div className="card-body d-flex flex-column align-items-start">
              <strong className="d-inline-block mb-2 text-success">Design</strong>
              <h3 className="mb-0">
                <a className="text-dark" href="#">Post title</a>
              </h3>
              <div className="mb-1 text-muted">Nov 11</div>
              <p className="card-text mb-auto">This is a wider card with supporting text below as a natural lead-in to additional content.</p>
              <a href="#">Continue reading</a>
            </div>
            <img style={{width: '200px', height: '300px'}} className="card-img-right flex-auto d-none d-md-block" src={logo} alt="Card image cap"/>
          </div>
        </div>
      </div>
      <div className="row mb-2">
        <div className="col-md-6">
          <div className="card flex-md-row mb-4 box-shadow h-md-250">
            <div className="card-body d-flex flex-column align-items-start">
              <strong className="d-inline-block mb-2 text-primary">World</strong>
              <h3 className="mb-0">
                <a className="text-dark" href="#">Featured post</a>
              </h3>
              <div className="mb-1 text-muted">Nov 12</div>
              <p className="card-text mb-auto">This is a wider card with supporting text below as a natural lead-in to additional content.</p>
              <a href="#">Continue reading</a>
            </div>            
            <img style={{width: '200px', height: '300px'}} className="card-img-right flex-auto d-none d-md-block" src={logo} alt="Card image cap"/>
          </div>
        </div>
        <div className="col-md-6">
          <div className="card flex-md-row mb-4 box-shadow h-md-250">
            <div className="card-body d-flex flex-column align-items-start">
              <strong className="d-inline-block mb-2 text-success">Design</strong>
              <h3 className="mb-0">
                <a className="text-dark" href="#">Post title</a>
              </h3>
              <div className="mb-1 text-muted">Nov 11</div>
              <p className="card-text mb-auto">This is a wider card with supporting text below as a natural lead-in to additional content.</p>
              <a href="#">Continue reading</a>
            </div>
            <img style={{width: '200px', height: '300px'}} className="card-img-right flex-auto d-none d-md-block" src={logo} alt="Card image cap"/>
          </div>
        </div>
      </div>
    </>
  )
}; 

// ========================================================================================================================================
// different layouts
// these are the different components that go inside the layout wraps 
// the main advanatge here is to test out different combination of landing components on a layout 

const heroText1 = [  
  `In our free newsletter, you get 2 emails per week.`,
  `Each email contains latest news and information for 2 companies roughly matching the type of companies you have set in your preference settings.`,
]; 
const heroText2 = [
  `In our premium newsletter, you get 3 emails per week.`,
  `Each email contains latest news and information for 30 companies exactly matching the type of companies you have set in your preference settings.`,  
  `This is a total of 360 companies per month.`,
  `Peter Lynch has a golden rule: If you study 10 companies, you'll find 1 for which the story is better than expected. If you study 50, you'll find 5.`,
  `At the minimum, stick with this 10% rule.`,
];                     
const heroText3 = [  
  `In our premium newsletter, the information contained for a company is not limited to the "latest" news.`,
  `But instead, we want to present you with as a complete picture as possible.`,
  `That's why we will include all links to news articles from the past old and recent.`,
];                                     

const Layout1 = ({  
}) => {
  useEffect(() => {                        
    console.log('Layout1');
  }, []);          
  return <>
  <LandingServiceIntro/>      
      <AboutUs/>           
      <LandingHero 
        heroTitle={`What's inside our Free Weekly Newsletter?`} 
        heroText={heroText1} // use this to import text form staticData.js
        imgUrl={landingHeroImag1}
        heroType={`left`} // heroType = `right` // means image is on right side
        emailBox={true}
        linkButton={`url`} // provide a link to something which is used in the link button        
      />             
      <LandingHero 
        heroTitle={`What's inside our Premium Weekly Newsletter?`} 
        heroText={heroText2} // use this to import text form staticData.js
        imgUrl={landingHeroImag1}
        heroType={`right`} 
        emailBox={false}
        linkButton={``} // provide a link to something which is used in the link button        
      />
      <LandingHero 
        heroTitle={`Premium Newsletter Content`} 
        heroText={heroText3} // use this to import text form staticData.js
        imgUrl={landingHeroImag1}
        heroType={`left`} 
        emailBox={false}
        linkButton={``} // provide a link to something which is used in the link button        
      />  
      <Footer/>
  </>
};  

// ========================================================================================================================================
// layout wraps
// these are the parent layouts that dictate the overview of the layout 
// create different layout wrappers to quickly change style of app 
// see Layouts folder

const LayoutWrap1 = ({children}) => {
  useEffect(() => {                        
    console.log('LayoutWrap1');
  }, []);          
  return <>                            
  <body
      className={`layoutwrap1`}
  >        
      {children}                                                  
  </body>
  </>
};  

// ========================================================================================================================================
// this is the component that controls the conditional rendering of layouts 
// before and after authentication

export default function FinalLayout({}) {  
    const useReduxState = () => {
        return useSelector(
          (state) => ({        
            loginTime: state.loginTime,
            profile: state.profile,
            uuid: state.uuid,
          }),
          shallowEqual
        );
      };    
    const { loginTime, profile, uuid } = useReduxState();
    useEffect(() => {
      console.log('mounted Layout', loginTime, profile, uuid);
    }, [loginTime]);
    return <>                                                  
          <LayoutWrap1>             
            {!loginTime ? <><Layout1/></> : <><Dashboard/></>}
          </LayoutWrap1>           
    </>
};  
