import {  
  replenishEverything  
} from './actions';
import isEmpty from "lodash.isempty";
// import isEqual from "lodash.isequal";

const persistMiddleware = ({ getState, dispatch }) => next => (action) => {  
    // ensure we are in the browser 
  if(typeof window === 'undefined') {    
    return next(action)
  }
  // ensure we have internet connection    
  // window.navigator.onLine should be true
  if(!window.navigator.onLine) {    
    return next(action)
  }    
  const { type, payload, meta} = action;
  if (type !== 'PERSIST') {
    return next(action)
  }     
  if (isEmpty(meta)) {
    return next(action)
  }
  const { label } = meta;
  if (label === 'replenish') {
    // flow for replenishing all reducers: 
    // check if local storage is empty or not 
    // if empty will return: replenishData null
    // if not then replenish the entire reducer
    let localStorageData = localStorage.getItem(`${type}`); 
    let replenishData = JSON.parse(localStorageData);    
    console.log(`log replenishData`, replenishData);    
    if(!isEmpty(replenishData)) {
      console.log(`exec replenishEverything !!!`);      
      dispatch(replenishEverything(replenishData)); 
    }
  }  
  if (label === 'persistProfile') {    
    // flow for persisting user obj: 
    // dispatch(persistProfile(profileObj)) is executed in 3 places 
    // inside getProfile() and loginPopup() and createAccountWithEmailAndPassword()
    // must make a copy of everything first 
    // we dont wnat to accidentally mutate the obj before it arrives to the reducer
    // optimisation point: 
    // remove sensitive info eg firebaseID/uuid
    // important to dstinguish what is sensitive and what is not !!! 
    // how to obfuscate firebaseID? if we do, then everytime we hit api we need to deobfuscate firebaseID
    // for now we persist everything but first obfuscate the firebaseID
    // we do not need to persist payment details as we will get new profile data everytime user successfully pays
    // JSON.stringify everything and save to local storage use label as the key    

    // think about what are the profile data we need to persist? 
    // firebaseID
    // profilePhotoURL
    // loginTime
    // some github data
    // payment history BUT not all so we need to check all details in stripe webhook object to remove sensitive data 

    // decide what things we dont need to persist
    // here now

    // const selected_keys = ['displayName', 'given_name', 'family_name', 
    // 'profilePhotoURL', 'purchased_items_list', "email", 'is_authenticated', 'firebaseID', 'isMonthlyMember'];         
    // const persistKeys = ['profile', 'is_authenticated'];     

    // on login and register successs
    // user: payload,
    // is_new_user: payload.isNewUser, 
    // sign_in_method: payload.signInMethod,
    // sign_in_time: payload.loginTime,
    // member_since: payload.creationTime,
    // display_name: payload.displayName,
    // photo_url: payload.profilePhotoURL,
    // email: payload.email,
    // current_user_id: payload.firebaseID,
    // is_authenticated: payload.firebaseID ? true : false,
    // provider_id: payload.providerId,

    // what do we need to persist from the payment data? 
    

    let copyObj = Object.assign({}, payload);    
    let localStorageData = localStorage.getItem(`${type}`); 
    console.log(`log payload for label localStorageData`, payload, localStorageData);
    if(!isEmpty(localStorageData)) {
      // get existing persissted data and merge then save
      let oldPersistObj = JSON.parse(localStorageData);       
      let persistObj = Object.assign(oldPersistObj, copyObj);
      localStorage.setItem(`${type}`, JSON.stringify(persistObj)); 
    } else {
      let persistObj = copyObj; 
      localStorage.setItem(`${type}`, JSON.stringify(persistObj)); 
    }    
  }  
  // MUST INCLUDE TO GO TO NEXT MIDDLEWARE
  next(action);
};  
export default persistMiddleware;
