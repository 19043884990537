export const ACTION_TYPES = {    
    // UI
    START_PAGE_LOADING: 'START_PAGE_LOADING',
    FINISHED_PAGE_LOADING: 'FINISHED_PAGE_LOADING',
    START_LOADING: 'START_LOADING',    
    FINISHED_LOADING: 'FINISHED_LOADING',    
    CLOSE_ALL_MODALS: 'CLOSE_ALL_MODALS',
    CLOSE_PROFILE_MODAL: 'CLOSE_PROFILE_MODAL',
    CLOSE_PRICING_MODAL: 'CLOSE_PRICING_MODAL',
    // register    
    REGISTER_SUCCESS: 'REGISTER_SUCCESS',
    REGISTER_FAIL: 'REGISTER_FAIL',    
    // auth    
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_FAIL: 'LOGIN_FAIL',
    LOGOUT: 'LOGOUT',            
    CREATE_NEW_PROFILE: 'CREATE_NEW_PROFILE',
    GET_PROFILE: 'GET_PROFILE',
    UPDATE_PROFILE: 'UPDATE_PROFILE',
    CLEAR_PROFILE: 'CLEAR_PROFILE',    
    ACCOUNT_DELETED: 'ACCOUNT_DELETED',
    REPLENISH_REDUX: 'REPLENISH_REDUX',            
    PERSIST: 'PERSIST',
    API: 'API',        
    // user preference 
    // do not use nested object for redux hence not using SET_USER_PREF
    // const steps = [`companyType`, `location`, `companySize`, `sector`, `industry`, `subIndustry`, `operationType`];
    // add action types
    SET_USER_PREF: 'SET_USER_PREF', 
    ADD_COMPANY_TYPE: 'ADD_COMPANY_TYPE',
    ADD_COMPANY_LOCATION: 'ADD_COMPANY_LOCATION',
    ADD_COMPANY_SIZE: 'ADD_COMPANY_SIZE',
    ADD_COMPANY_REV_SIZE: 'ADD_COMPANY_REV_SIZE',
    ADD_COMPANY_STAFF_SIZE: 'ADD_COMPANY_STAFF_SIZE',
    ADD_COMPANY_SECTOR: 'ADD_COMPANY_SECTOR',
    ADD_COMPANY_INDUSTRY: 'ADD_COMPANY_INDUSTRY',
    ADD_COMPANY_SUB_INDUSTRY: 'ADD_COMPANY_SUB_INDUSTRY',
    ADD_COMPANY_OPERATION_TYPE: 'ADD_COMPANY_OPERATION_TYPE',
    // remove action types
    REMOVE_COMPANY_TYPE: 'REMOVE_COMPANY_TYPE',
    REMOVE_COMPANY_LOCATION: 'REMOVE_COMPANY_LOCATION',
    REMOVE_COMPANY_SIZE: 'REMOVE_COMPANY_SIZE',
    REMOVE_COMPANY_SECTOR: 'REMOVE_COMPANY_SECTOR',
    REMOVE_COMPANY_INDUSTRY: 'REMOVE_COMPANY_INDUSTRY',
    REMOVE_COMPANY_SUB_INDUSTRY: 'REMOVE_COMPANY_SUB_INDUSTRY',
    REMOVE_COMPANY_OPERATION_TYPE: 'REMOVE_COMPANY_OPERATION_TYPE',
};  

