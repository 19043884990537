import React, { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import isEmpty from 'lodash.isempty';
import DashboardModals from './DashboardModals';

export default function Dashboard({}) {    
      const useReduxState = () => {
          return useSelector(
            (state) => ({        
              loginTime: state.loginTime,
              profile: state.profile,
              uuid: state.uuid,
            }),
            shallowEqual
          );
        };    
      const { loginTime, profile, uuid } = useReduxState();
      useEffect(() => {
        console.log('mounted Layout', loginTime, profile, uuid);
      }, [loginTime]);
      return <>              
        <div 
            style={{                                      
                justifyContent: 'center',      
                marginBottom: '30rem'            
            }}
            className="row"
        >                      
        <hr/>        
          {
            <>            
              <div 
                style={{
                    display: 'flex',                
                    justifyContent: 'center',
                    marginTop: '1rem',
                    width: '100%'                                
                }}                
              >                                  
                <input className="form-control mr-sm-2 search-bar" type="search" placeholder="Search" aria-label="Search"/>                                        
              </div>   
              <div 
                style={{
                    display: 'flex',                
                    justifyContent: 'center',
                    flexWrap: 'wrap',
                    marginTop: '1rem',
                    marginBottom: '5rem', // set this in style sheet
                    width: '80%'                                
                }}                
              >                             
                <div 
                    style={{                    
                        marginRight: '1rem'
                    }}
                    className="form-check form-check-inline"
                >
                    <input className="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1"/>
                    <label className="form-check-label" for="inlineCheckbox1">1</label>
                </div>
                <div 
                    style={{                    
                        marginRight: '1rem'
                    }}
                    className="form-check form-check-inline"
                >
                    <input className="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2"/>
                    <label className="form-check-label" for="inlineCheckbox2">2</label>
                </div>
                <div 
                    style={{                    
                        marginRight: '1rem'
                    }}
                    className="form-check form-check-inline"
                >
                    <input className="form-check-input" type="checkbox" id="inlineCheckbox3" value="option3" disabled/>
                    <label className="form-check-label" for="inlineCheckbox3">3 (disabled)</label>
                </div>
                <div 
                    style={{                    
                        marginRight: '1rem'
                    }}
                    className="form-check"
                >
                    <input className="form-check-input" type="checkbox" value="" id="defaultCheck1"/>
                    <label className="form-check-label" for="defaultCheck1">
                        Default checkbox
                    </label>
                </div>
                <div 
                    style={{                    
                        marginRight: '1rem'
                    }}
                    className="form-check form-switch"
                >
                    <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault"/>
                    <label className="form-check-label" for="flexSwitchCheckDefault">Default switch checkbox input</label>
                </div>
                </div>              
            </>           
            }                          

            {/* <DashboardModals/> */}
            
    {
        <div
            // the following inline style works              
            // learning points: 
            // use additional className to make it when in desktop view: 
            // card-center-desktop-view
            // width: '50%',
            // margin: '0 auto'
            // but remove class in mobile view            
            // style={{                
            //     width: '50%',
            //     margin: '0 auto'                
            // }}            
            className="card company-card"
        >            
            {/* <div             
                style={{                
                    backgroundColor: 'none',                    
                }}                
                className="card-header"
            >
                COmpany Name
            </div> */}
            <h5
                style={{
                    // backgroundColor: 'var(--indigo)',                
                    padding: '0.2rem',
                    marginTop: '1rem',
                    textAlign: 'center',
                    fontWeight: 'bold',
                }}                
            >
                InterDigital Inc
            </h5>                
            <hr/>
            <table 
                id='mobileviewtable1'
                style={{
                  color: "#fff",      
                  marginTop: '1rem'                
                }}                             
                className="table">                
                <tbody>
                    <tr
                     style={{
                        background: 'var(--light)',
                        color: 'var(--dark)',
                      }}
                    >
                    <th scope="row">                    
                    <td>Company location:</td>
                    </th>        
                    <td>
                        USA
                    </td>                        
                    </tr>

                    <tr
                    style={{
                      background: 'var(--light)',
                      color: 'var(--dark)',
                    }}
                    >
                        <th 
                        scope="row"                          
                        >                    
                        <td>Operating as:</td>
                        </th>                                                        
                        <td>grading contractor</td>                        
                    </tr>

                    <tr
                    style={{
                      background: 'var(--light)',
                      color: 'var(--dark)',
                    }}
                    >
                        <th 
                        scope="row"                          
                        >                    
                        <td>Revenue in the region of:</td>
                        </th>                                                        
                        <td>$124 million</td>                        
                    </tr>                  
                      </tbody>
                    </table> 

            <div className="card-body">
            {/* <h5 className="card-title">Company Information:</h5>
            <ul className="list-group list-group-flush">
                    <li className="list-group-item">
                        <span style={{                        
                        fontWeight: 'bold',
                        marginRight: '3rem'
                    }}> Company location: </span>                         
                        <span style={{                        
                        fontWeight: 'bold',
                        textAlign: 'center'
                    }}> {`USA`} </span>
                    </li>
                    <li className="list-group-item">
                        <span style={{                        
                        fontWeight: 'bold',
                        marginRight: '3rem'
                    }}> Revenue in the region of: </span> 
                        <span style={{                        
                        fontWeight: 'bold',
                        textAlign: 'center'
                    }}> {`$124 million`} </span>
                    </li>
            </ul> */}            
            <div className="card-body">
                    <h5 className="card-title">News Highlights</h5>
                    <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
            </div>
            <hr/>
                <ul className="list-group list-group-flush">
                    <li className="list-group-item">Cras justo odio</li>
                    <li className="list-group-item">Dapibus ac facilisis in</li>
                    <li className="list-group-item">Vestibulum at eros</li>
                    <li className="list-group-item">Vestibulum at eros</li>
                    <li className="list-group-item">Vestibulum at eros</li>
                    <li className="list-group-item">Vestibulum at eros</li>
                    <li className="list-group-item"><a href="#" className="card-link">Another link</a></li>
                    <li className="list-group-item"><a href="#" className="card-link">Another link</a></li>
                    <li className="list-group-item"><a href="#" className="card-link">Another link</a></li>                    
                </ul>
                <div className="card-body">
                    <a href="#" className="card-link">Card link</a>
                    <a href="#" className="card-link">Another link</a>
                </div>
                <p className="card-text">With supporting text below as a natural lead-in to additional content.</p>
                <div className="d-flex flex-row-reverse">
                    <a href="#" className="btn btn-primary">Save this card</a>              
                </div>                
            </div>
        </div>
    }         
            {/* <div
                style={{
                    width: '18rem'
                }} 
                className="card"         
            >
                <img className="card-img-top" src="..." alt="Card image cap"/>
                <div className="card-body">
                    <h5 className="card-title">Card title</h5>
                    <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                </div>
                <ul className="list-group list-group-flush">
                    <li className="list-group-item">Cras justo odio</li>
                    <li className="list-group-item">Dapibus ac facilisis in</li>
                    <li className="list-group-item">Vestibulum at eros</li>
                </ul>
                <div className="card-body">
                    <a href="#" className="card-link">Card link</a>
                    <a href="#" className="card-link">Another link</a>
                </div>
            </div>
            <div
                style={{
                    width: '18rem'
                }} 
                className="card"         
            >
                <img className="card-img-top" src="..." alt="Card image cap"/>
                <div className="card-body">
                    <h5 className="card-title">Card title</h5>
                    <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                </div>
                <ul className="list-group list-group-flush">
                    <li className="list-group-item">Cras justo odio</li>
                    <li className="list-group-item">Dapibus ac facilisis in</li>
                    <li className="list-group-item">Vestibulum at eros</li>
                </ul>
                <div className="card-body">
                    <a href="#" className="card-link">Card link</a>
                    <a href="#" className="card-link">Another link</a>
                </div>
            </div> */}
        </div>
      </>
  };  