import React, { useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import {  
  makePayment,
  startLoading,  
} from './redux/actions';
const isTest = false;

export default function StripeCheckoutButton({planType}) {
  const useReduxState = () => {
    return useSelector(
      (state) => ({                  
        // need to grab userId and email 
        email: state.username,
        firebaseID: state.firebaseID,
        loadingTypes: state.loadingTypes,
        loginTime: state.loginTime,
      }),
      shallowEqual
    )};    
    // we need to listen for paymet loading events 
  const { loadingTypes, loginTime, firebaseID, email } = useReduxState();
  const dispatch = useDispatch();  
  useEffect(() => {    
    console.log(`stripe button mounted`, planType)
  }, [loadingTypes, loginTime, firebaseID, email, planType]);

  const startPaymentProcess = (e, planType) => {        
      // test price id
      // production price id
      // put the ids in env vars 
      let funcName = `startPaymentProcess`;
      try {      
          e.preventDefault();    
          // start button loading 
          // start page loading in apiMiddleware
          dispatch(startLoading(funcName));
          if(!loginTime) return `user not logged in`;                  
          if (!planType) {
            alert('Please choose a plan first!');
            return true;
          }
          let paymentObj = {
            created_at: Date.now(),
            email: email,
            sku: `${email}-${firebaseID}-${planType}-${Date.now()}`,
            plan: `${planType}`,
            // lookup_key: `${priceId}`,
          };   
          if (planType === 'monthly') {
            if (isTest) {        
              paymentObj['lookup_key'] = 'price_1KcaTwCXj0cKCM7gi4Ab4obz'; 
              return true;
            }      
              paymentObj['lookup_key'] = 'price_1KbQcJCXj0cKCM7gFgUGdLoN'; 
          }
          if (planType === 'quarterly') {
            return true;
          }
          if (planType === 'half-yearly') {
            return true;
          }
          if (planType === 'yearly') {
              if (isTest) {        
                paymentObj['lookup_key'] = 'price_1KcaTwCXj0cKCM7gi4Ab4obz'; 
                return true;
              }              
                paymentObj['lookup_key'] = 'price_1KbQcJCXj0cKCM7gFgUGdLoN'; 
          }                       
          console.log(`log paymentObj before dispatch ${funcName}`, paymentObj);
          dispatch(makePayment(paymentObj));
      } catch (err) {
        console.log(`err in ${funcName}`, err);
      }
  }; 

  return <>
      <div
       style={{
        cursor: 'pointer',        
      }}
        className="stripe-btn"
        onClick={(e) => {
          startPaymentProcess(e, planType.toLowerCase());
        }}
      >
        <div className="google-icon-wrapper">
          <i
            style={{
              width: '1rem',
              height: '1rem',
              marginTop: '0.5rem',
              marginLeft: '0.5rem',
              color: '#32325d',
            }}
            className="fa fa-cc-stripe"
            aria-hidden="true"
          ></i>
        </div>
        <p className="btn-text">
          <b>Pay with Stripe</b>
        </p>
      </div>
    </>  
}; 
