import { createStore, applyMiddleware, compose } from 'redux';
import logger from 'redux-logger';
import { reducer, initialState } from './reducer';
import persistMiddleware from './persistMiddleware';
import apiMiddleware from './apiMiddleware';
const middleware = [logger, persistMiddleware]; 
const store = createStore(
  reducer,
  initialState,    
  compose(applyMiddleware(...middleware))
);

export default store
