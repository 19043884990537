import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Modal, Container, Row } from 'react-bootstrap';
import isEqual from 'lodash.isequal';
import isEmpty from 'lodash.isempty';
import Loading from './Loading';
import HelperService from './utils/services/HelperService';
import firebaseSer from './utils/services/FirebaseService';
const helperSer = new HelperService();

export default function LoginModal(props) {  
// notes: 
// to use in nextjs need to import Router only  
// 26feb23: added in loading logic to LoginModal
// updated 2mar23:
// added conitional rednering to show that user is being logged in so dont press any button in the meantime 
// we let the user know the loginModal knows that it has tried to login by showing loading 
// then after 3 seconds the full page loader pops up
// full page loader should be finished after the finishedLoading
// so finishedLoading is before finishedPageLoading 
// learning points:
// for logout we can just show full page loading showing logging out
  const useReduxState = () => {
      return useSelector(
        (state) => ({
          loadingTypes: state.loadingTypes,
          loginTime: state.loginTime,
        }),
        shallowEqual
  );};    
  const { loadingTypes, loginTime } = useReduxState();
  const dispatch = useDispatch();  
  const [fieldObj, setFieldsText] = useState({});
  const [checkEmail, setCheckEmail] = useState(true);
  const [checkPassword, setCheckPassword] = useState(true);
  const [comparePassword, setComparePassword] = useState(true);
  const [noUserFound, setNoUserFound] = useState(false);
  const [incorrectPassword, setIncorrectPassword] = useState(false);
  const invalidPwMsg = 'Passwords must be 6-12 characters long, contain 1 uppercase, 1 lowercase letter and at least 1 symbol.';
  // updated loading logic here:
  const [thisLoadingType, setLoadingType] = useState([]); // on page refresh or rerender user can open LoginModal as this is null      
  const [isLoading, setIsLoading] = useState(false);
  const listeningForEvents = [`createAccountWithEmailAndPassword`, `loginWithEmailAndPassword`];

  useEffect(() => {    
    console.log(`log AuthButtons`, loginTime, loadingTypes, thisLoadingType);
    listenForLoadingTypes(loadingTypes);       
  }, [loginTime, loadingTypes, isLoading]);

  const listenForLoadingTypes = (loadingTypes) => {    
    let funcName = `listenForLoadingTypes`;
    try {                  
      setLoadingType(prevState => {           
        let findLoadingEvent = loadingTypes.filter(x => listeningForEvents.includes(x)); // current redux
        if(!isEmpty(findLoadingEvent)) {          
          setIsLoading(true);
        }
        let checkPrevStateToCloseModal = prevState.filter(x => listeningForEvents.includes(x)); // prevState in component         
        if(checkPrevStateToCloseModal.length > 0 && findLoadingEvent.length === 0) {          
          // update is loading in component 
          setIsLoading(false);
        }
        let arr = [...prevState, ...findLoadingEvent];
        return arr;
      });
      return true;
    } catch (err) {      
      console.error(`err in ${funcName}: ${err}`);
    }
  };
  

  // changes 10feb23:
  // need to add loader so when user has clicked submit the user can see something happening 
  // on submit loadin turns true and login modal needs to listen for changes in loadingTypes
  // these are the loadingTypes we should be listening for: 
  // `createAccountWithEmailAndPassword` and `loginWithEmailAndPassword`    
  // if loadingTypes array changes in redux modal component updated as useEffect fires up  
  // we should actually listen for this inside AuthButtons
  // we need to listen in both LoginModal and AuthButtons    
  // AuthButtons responsible for removing the entire modal
  // LoginModal responsible for removing the login button and change to loading spinner 

  const signUpOrLogin = async (e) => {
    let funcName = `signUpOrLogin`;
    try {
      e.preventDefault();
    //   await firebaseSer.loginPopup(dispatch, 'google', router); // nextjs
    await firebaseSer.loginPopup(dispatch, 'google'); // reactjs
    } catch (err) {      
      console.error(`err in ${funcName}: ${err}`);
    }
  };

  const onChange = async (e) => {    
    let key = e.target.name.trim();
    let value = e.target.value.trim();
    let newFieldObj = Object.assign({}, fieldObj);
    newFieldObj[key] = value;
    setFieldsText(newFieldObj);
  };

  const equalCheck = async (passowrd, passowrd2) => {
    let funcName = `equalCheck`;
    try {
      let equalCheck = isEqual(passowrd, passowrd2);
      if (!equalCheck) {
        setComparePassword(false);
        return equalCheck;
      } else {
        setComparePassword(true);
        return equalCheck;
      }
    } catch (err) {      
      console.error(`err in ${funcName}: ${err}`);
    }
  };

  const pwCheck = async (pw) => {
    let funcName = `pwCheck`;
    try {
      let res = await helperSer.validPassword(pw);      
      if (!res) {        
        setCheckPassword(res);
        return false;
      } else {
        setCheckPassword(res);
        return true;
      }
    } catch (err) {      
      console.error(`err in ${funcName}: ${err}`);
    }
  };

  const emailCheck = async (email) => {
    let funcName = `emailCheck`;
    try {
      let res = await helperSer.validEmail(email);
      // //console.log('log res emailCheck', res);
      if (!res) {
        // if str has err
        setCheckEmail(res);
        return false;
      } else {
        setCheckEmail(res);
        return true;
      }
    } catch (err) {
      //console.log(`err in ${funcName}: ${err}`);
      console.error(`err in ${funcName}: ${err}`);
    }
  };

  const reserPassword = async () => {
    let funcName = `reserPassword`;
    try {
      let forgotEmail = fieldObj.forgotEmail;
      // //console.log('log forgotEmail in reserPassword', forgotEmail);
      if (isEmpty(forgotEmail)) return 'forgot email is empty str';
      // do email check first before set
      let resEmail = await emailCheck(forgotEmail);
      // //console.log('log resEmail', resEmail);
      if (!resEmail) return false;
      let res = await firebaseSer.doPasswordReset(forgotEmail);
      // //console.log('log reserPassword', res);
      if (
        res ===
        'There is no user record corresponding to this identifier. The user may have been deleted.'
      ) {
        // err in doPasswordReset: Error: There is no user record corresponding to this identifier. The user may have been deleted.
        // inform user there is no such email
        let alertStr =
          'There is no user record corresponding to this email. Please try again or email support at closetoracle@gmail.com';
        return alert(alertStr);
      }
      // if res === undefined = successfully sent reset password email
      return true;
    } catch (err) {
      //console.log(`err in ${funcName}: ${err}`);
      console.error(`err in ${funcName}: ${err}`);
    }
  };

  const submitForm = async (e) => {
    // do all validation checks on submit
    let funcName = `submitForm`;
    try {
      e.preventDefault();
      // check what type of form it is
      let email = fieldObj.email;
      let password = fieldObj.password;
      // //console.log('login type is', props.props);
      if (props.props === 'signup') {
        let password2 = fieldObj.confirmP;
        // do passowrd equal check first
        let resEqual = await equalCheck(password, password2);
        if (!resEqual) return false;
        // do passowrd validation check
        let resPassword = await pwCheck(password);
        // //console.log('log resPassword', resPassword);
        if (!resPassword) return false;
        // do passowrd validation check
        // check str and show err message of err
        let resEmail = await emailCheck(email);
        // //console.log('log resEmail', resEmail);
        if (!resEmail) return false;
        // //console.log('log everything ok can create account');
        // if everythin ok execute firebase function
        // await firebaseSer.createAccountWithEmailAndPassword(email, password, dispatch, router); // nextjs
        await firebaseSer.createAccountWithEmailAndPassword(email, password, dispatch);
      } else {
        // do passowrd validation check
        let resPassword = await pwCheck(password);
        // //console.log('log resPassword', resPassword);
        if (!resPassword) return false;
        // do passowrd validation check
        // check str and show err message of err
        let resEmail = await emailCheck(email);
        // //console.log('log resEmail', resEmail);
        if (!resEmail) return false;
        // //console.log('log everything ok can create account');
        // if everythin ok execute firebase function
        // let res = await firebaseSer.loginWithEmailAndPassword(email, password, dispatch, router); // nextjs
        let res = await firebaseSer.loginWithEmailAndPassword(email, password, dispatch);
        // if err, loginWithEmailAndPassword: Error: There is no user record corresponding to this identifier. The user may have been deleted.
        // set state with err message showing user invalid username login
        if (res.code === 'auth/user-not-found') {
          // //console.log('show user login error no user found',res.message);
          setNoUserFound(
            'No user found for this email address. Please try again.'
          );
        }
        // //console.log('show user login error no user found',res.code);
        if (res.code === 'auth/wrong-password') {
          // //console.log('show user login error no user found',res.message);
          setIncorrectPassword(`${res.message} Please try again.`);
        }
      }
    } catch (err) {
      //console.log(`err in ${funcName}: ${err}`);
      console.error(`err in ${funcName}: ${err}`);
    }
  };

  useEffect(() => {}, [comparePassword]);

  const SignUpEle = () => {
    try {
      let fields = ['username', 'email', 'passowrd', 'confirm'];
      return (
        <>         
          <Row>
            <span
              style={{
                marginBottom: '1rem',
              }}
            >
              Email address
            </span>

            {!checkEmail ? (
              <span
                style={{
                  fontSize: '12px',
                  color: 'red',
                }}
              >
                Invalid email address.
              </span>
            ) : (
              <></>
            )}
            <input
              style={{
                marginBottom: '1rem',
                padding: '1rem',
                border: '2px solid indigo',
                borderTopLeftRadius: '0.5rem',
                borderTopRightRadius: '0.5rem',
                borderBottomRightRadius: '0.5rem',
                borderBottomLeftRadius: '0.5rem',
              }}
              type="text"
              data-field="email"
              aria-label="Email"
              name="email"
              value={fieldObj.email}
              onChange={(e) => onChange(e)}
            />
          </Row>
          <Row>
            <span
              style={{
                marginBottom: '1rem',
              }}
            >
              Password
            </span>
            {!checkPassword ? (
              <span
                style={{
                  fontSize: '12px',
                  color: 'red',
                }}
              >
                Invalid password. {invalidPwMsg}
              </span>
            ) : (
              <></>
            )}
            <input
              style={{
                marginBottom: '1rem',
                padding: '1rem',
                border: '2px solid indigo',
                borderTopLeftRadius: '0.5rem',
                borderTopRightRadius: '0.5rem',
                borderBottomRightRadius: '0.5rem',
                borderBottomLeftRadius: '0.5rem',
              }}
              type="password"
              data-field="password"
              aria-label="Password"
              name="password"
              value={fieldObj.password}
              onChange={(e) => onChange(e)}
            />
          </Row>

          <Row>
            <span
              style={{
                marginBottom: '1rem',
              }}
            >
              Confirm password
            </span>
            {!comparePassword ? (
              <span
                style={{
                  fontSize: '12px',
                  color: 'red',
                }}
              >
                Passwords do not match.
              </span>
            ) : (
              <></>
            )}
            <input
              style={{
                marginBottom: '1rem',
                padding: '1rem',
                border: '2px solid indigo',
                borderTopLeftRadius: '0.5rem',
                borderTopRightRadius: '0.5rem',
                borderBottomRightRadius: '0.5rem',
                borderBottomLeftRadius: '0.5rem',
              }}
              type="password"
              data-field="confirmP"
              aria-label="ConfirmP"
              name="confirmP"
              value={fieldObj.confirmP}
              onChange={(e) => onChange(e)}
            />
          </Row>
        </>
      );
    } catch (err) {
      //console.log(`err in signUpEle`, err);
    }
  };

  const LoginEle = () => {
    let fields = ['email', 'passowrd'];
    try {
      return (
        <>
          <Row>
            <span
              style={{
                marginBottom: '1rem',
              }}
            >
              Email address
            </span>
            {!checkEmail ? (
              <span
                style={{
                  fontSize: '12px',
                  color: 'red',
                }}
              >
                Invalid email address.
              </span>
            ) : (
              <></>
            )}

            {noUserFound ? (
              <span
                style={{
                  fontSize: '12px',
                  color: 'red',
                }}
              >
                {noUserFound}
              </span>
            ) : (
              <></>
            )}

            <input
              style={{
                marginBottom: '1rem',
                padding: '1rem',
                border: '2px solid indigo',
                borderTopLeftRadius: '0.5rem',
                borderTopRightRadius: '0.5rem',
                borderBottomRightRadius: '0.5rem',
                borderBottomLeftRadius: '0.5rem',
              }}
              type="text"
              data-field="email"
              aria-label="Email"
              name="email"
              value={fieldObj.email}
              onChange={(e) => onChange(e)}
            />
          </Row>
          <Row>
            <span
              style={{
                marginBottom: '1rem',
              }}
            >
              Password
            </span>
            {!checkPassword ? (
              <span
                style={{
                  fontSize: '12px',
                  color: 'red',
                }}
              >
                Invalid password. {invalidPwMsg}
              </span>
            ) : (
              <></>
            )}
            {incorrectPassword ? (
              <span
                style={{
                  fontSize: '12px',
                  color: 'red',
                }}
              >
                {incorrectPassword}
              </span>
            ) : (
              <></>
            )}
            <input
              style={{
                marginBottom: '1rem',
                padding: '1rem',
                border: '2px solid indigo',
                borderTopLeftRadius: '0.5rem',
                borderTopRightRadius: '0.5rem',
                borderBottomRightRadius: '0.5rem',
                borderBottomLeftRadius: '0.5rem',
              }}
              type="password"
              className="signup__field__inputs__input signup__field__inputs__input--current-email"
              data-field="password"
              aria-label="Email"
              name="password"
              value={fieldObj.password}
              onChange={(e) => onChange(e)}
            />
          </Row>
        </>
      );
    } catch (err) {
      //console.log(`err in signUpEle`, err);
    }
  };

  const ForgotPassword = () => {
    try {
      return (
        <>
          <Row>
            <span
              style={{
                marginBottom: '1rem',
                fontWeight: 'bold',
              }}
            >
              Forgot password
            </span>
            <span
              style={{
                fontSize: '0.8rem',
              }}
            >
              Type the email you registered with and we will send you a passowrd
              reset email.
            </span>
            <input
              // execte doPasswordReset
              style={{
                marginBottom: '1rem',
                padding: '1rem',
                border: '2px solid indigo',
                borderTopLeftRadius: '0.5rem',
                borderTopRightRadius: '0.5rem',
                borderBottomRightRadius: '0.5rem',
                borderBottomLeftRadius: '0.5rem',
              }}
              type="text"
              data-field="forgotEmail"
              aria-label="ForgotEmail"
              name="forgotEmail"
              value={fieldObj.forgotEmail}
              onChange={(e) => onChange(e)}
            />
          </Row>
        </>
      );
    } catch (err) {
      //console.log(`err in signUpEle`, err);
    }
  };

  const GoogleEle = ({ loginType }) => {
    try {
      return (
        <>
          <Row>
            <ul
              style={{
                display: 'flex',
                flexDirection: 'row',
                cursor: 'pointer',
              }}
              onClick={(e) => signUpOrLogin(e)}
            >
              <div className="google-icon-wrapper">
                <svg
                  className="google-icon"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="35"
                  height="40"
                >
                  <g transform="matrix(1, 0, 0, 1, 27.009001, -39.238998)">
                    <path
                      fill="#4285F4"
                      d="M -3.264 51.509 C -3.264 50.719 -3.334 49.969 -3.454 49.239 L -14.754 49.239 L -14.754 53.749 L -8.284 53.749 C -8.574 55.229 -9.424 56.479 -10.684 57.329 L -10.684 60.329 L -6.824 60.329 C -4.564 58.239 -3.264 55.159 -3.264 51.509 Z"
                    />
                    <path
                      fill="#34A853"
                      d="M -14.754 63.239 C -11.514 63.239 -8.804 62.159 -6.824 60.329 L -10.684 57.329 C -11.764 58.049 -13.134 58.489 -14.754 58.489 C -17.884 58.489 -20.534 56.379 -21.484 53.529 L -25.464 53.529 L -25.464 56.619 C -23.494 60.539 -19.444 63.239 -14.754 63.239 Z"
                    />
                    <path
                      fill="#FBBC05"
                      d="M -21.484 53.529 C -21.734 52.809 -21.864 52.039 -21.864 51.239 C -21.864 50.439 -21.724 49.669 -21.484 48.949 L -21.484 45.859 L -25.464 45.859 C -26.284 47.479 -26.754 49.299 -26.754 51.239 C -26.754 53.179 -26.284 54.999 -25.464 56.619 L -21.484 53.529 Z"
                    />
                    <path
                      fill="#EA4335"
                      d="M -14.754 43.989 C -12.984 43.989 -11.404 44.599 -10.154 45.789 L -6.734 42.369 C -8.804 40.429 -11.514 39.239 -14.754 39.239 C -19.444 39.239 -23.494 41.939 -25.464 45.859 L -21.484 48.949 C -20.534 46.099 -17.884 43.989 -14.754 43.989 Z"
                    />
                  </g>
                </svg>
              </div>
              <p
                style={{
                  backgroundColor: '#4285f4',
                  color: 'white',
                  textAlign: 'center',
                  width: '100%',
                  marginLeft: '1rem',
                  marginBottom: '1rem',
                  padding: '0.5rem',
                  border: '2px solid #4285f4',
                  borderTopLeftRadius: '0.5rem',
                  borderTopRightRadius: '0.5rem',
                  borderBottomRightRadius: '0.5rem',
                  borderBottomLeftRadius: '0.5rem',
                }}
              >
                {loginType === 'signup' ? (
                  // <b>Register with google</b>
                  <b>Sign in with google</b>
                ) : (
                  <b>Sign in with google</b>
                )}
              </p>
            </ul>
          </Row>
        </>
      );
    } catch (err) {
      //console.log(`err in GoogleEle`, err);
    }
  };

  const SubmitEle = ({ loginType }) => {
    try {
      return (
        <>
          <Row>
            <div
              style={{
                backgroundColor: 'var(--indigo)',
                color: 'white',
                textAlign: 'center',
                // marginBottom: '5rem',
                padding: '1rem',
                border: '2px solid var(--indigo)',
                borderTopLeftRadius: '0.5rem',
                borderTopRightRadius: '0.5rem',
                borderBottomRightRadius: '0.5rem',
                borderBottomLeftRadius: '0.5rem',
              }}
              type="button"
              // data-field="submit"
              // aria-label="Submit"
              // name="submit"
              onClick={(e) => submitForm(e)}
            >
              {loginType === 'signup' ? 'Create account' : 'Login'}
            </div>
          </Row>

          <Row style={{ marginBottom: '8rem' }}>
            <p>
              {loginType === 'signup'
                ? 'By creating this account, you agree to our'
                : 'By logging in, you agree to our'}{' '}
              <a
                style={{
                  // fontSize: '2rem',
                  color: 'var(--blue)',
                  cursor: 'pointer',
                }}
                onClick={() =>
                  window.open('https://closetoracle.com/terms', '_blank')
                }
              >
                Terms
              </a>{' '}
              and{' '}
              <a
                style={{
                  // fontSize: '2rem',
                  color: 'var(--blue)',
                  cursor: 'pointer',
                }}
                onClick={() =>
                  window.open(
                    'https://closetoracle.com/privacy_policy',
                    '_blank'
                  )
                }
              >
                Privacy Policy
              </a>
              .
            </p>
          </Row>          
        </>
      );
    } catch (err) {
      //console.log(`err in GoogleEle`, err);
    }
  };

  return (
    <>
      <Modal
        style={{
          marginTop: '5rem',
        }}
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header
          style={{
            // --indigo: #6610f2;
            // --color-theme-7-3378-custom: #7900ff;
            // --googleBgColor5: linear-gradient(to top, #e14fad 0%, #f9d423 100%);
            backgroundColor: 'var(--indigo)',
            color: 'white',
          }}
          closeButton
        >
          <Modal.Title id="contained-modal-title-vcenter">
            {props.props === 'signup' ? 'Register with email' : 'Sign in with email'}
          </Modal.Title>
        </Modal.Header>        
          {isLoading ? 
            <>
            <Modal.Body className="show-grid">
                  <Container
                    style={{
                      padding: '3rem',

                    }}
                  >            
                    <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          padding: '1rem',                                                        
                          textAlign: 'center',                      
                          // backgroundColor: 'var(--indigo)',                              
                          color: 'white',                                                                
                        }}
                      >
                        <p
                          style={{
                            textAlign: 'center',
                            // marginBottom: '5rem',
                            fontSize: '20px',
                            fontWeight: 'bold',
                            padding: '2rem',
                            color: 'var(--dark)'
                          }}
                        >                          
                          Authenticating...  
                        </p>     
                        <Loading
                          loading={isLoading} 
                          type={`audio`}
                        />                    
                      </div>                                          
                  </Container>
                </Modal.Body>
            </> :
            <>
                <Modal.Body className="show-grid">
                  <Container
                    style={{
                      padding: '3rem',
                    }}
                  >            
                    <br />
                    <Row style={{ marginBottom: '0.5rem' }}>
                      {props.props === 'signup'
                        ? 'Register with email'
                        : 'Sign in with email'}
                    </Row>
                    <Row style={{ borderBottom: 'solid 2px indigo' }} />
                    <br />        
                    {              
                      props.props === 'signup' ? SignUpEle() : LoginEle()
                    }
                    <SubmitEle loginType={props.props} />
                    {props.props === 'signup' ? (
                      <></>
                    ) : (
                      <>
                        {                  
                          ForgotPassword()
                        }
                        <Row style={{ marginBottom: '8rem' }}>
                          <div
                            style={{
                              backgroundColor: 'var(--indigo)',
                              color: 'white',
                              textAlign: 'center',                      
                              padding: '1rem',
                              border: '2px solid var(--indigo)',
                              borderTopLeftRadius: '0.5rem',
                              borderTopRightRadius: '0.5rem',
                              borderBottomRightRadius: '0.5rem',
                              borderBottomLeftRadius: '0.5rem',
                            }}
                            type="button"                    
                            onClick={(e) => reserPassword(e)}
                          >
                            Reset Password
                          </div>
                        </Row>{' '}
                      </>
                    )}
                  </Container>
                </Modal.Body>
            </>
            }
        <Modal.Footer>          
        </Modal.Footer>
      </Modal>
    </>
  );
}

