import React, {useEffect, useState} from 'react'; 
import isEmpty from 'lodash.isempty';
import { COMPONENT_STATIC_DATA } from './data/staticData';

export default function LandingHero({ imgUrl, heroType, emailBox, linkButton, heroText, heroTitle }) {    
    // use COMPONENT_STATIC_DATA to indicate left or right Hero component 
    // if we use COMPONENT_STATIC_DATA we need to pass data into Hero ocmponent from App.js
    // we need to pass data in individually     
    let {        
        businessName,
        copyrightYears,
        interLinks,
        blogLinks,
        tutorialLinks,
        socialLinks
    } = COMPONENT_STATIC_DATA.footerProps;
    useEffect(() => {                    
        console.log(`mounted LandingHero`, imgUrl, heroType, emailBox, linkButton, heroText)
    }, []);   

    const goToLink = (e, urlLink) => {        
    }; 

     return <>
        {
           <div className="container col-xxl-8 px-4 py-5">
            {
                heroType === 'right' ? 
                <>
                <div className="row flex-lg-row-reverse align-items-center g-5 py-5">           
                    {
                        // HERO IMG on right side 
                        <div className="col-10 col-sm-8 col-lg-6">
                            <img src={imgUrl} className="d-block mx-lg-auto img-fluid" alt="hero-img" width="700" height="500" loading="lazy"/>
                        </div>
                    }
                    { 
                        // HERO TXT on left side 
                        <div className="col-lg-6">                                             
                                <h1 className="display-5 fw-bold lh-1 mb-3">{heroTitle ? heroTitle : ``}</h1>   
                                {
                                    !isEmpty(heroText) ? 
                                    <>
                                        {heroText.map(text => (
                                            <>
                                                <p className="lead">
                                                    {text}                                                    
                                                </p>              
                                            </>
                                        ))}
                                    </> : 
                                    <>
                                    </>
                                }                                                                        
                                {
                                    linkButton ? 
                                    <>
                                        <div className="d-grid gap-2 d-md-flex justify-content-md-start">
                                            <button 
                                              onClick={e => {goToLink(e, linkButton)}}
                                            type="button" className="btn btn-primary btn-lg px-4 me-md-2"
                                            >
                                            Newsletter Samples
                                            </button>                    
                                        </div>
                                    </> :
                                    <></>
                                } 
                            </div>
                        }
                </div>
                </> :
                <>
                       <div className="row flex-lg-row align-items-center g-5 py-5">           
                    {
                        // HERO IMG on right side 
                        <div className="col-10 col-sm-8 col-lg-6">
                            <img src={imgUrl} className="d-block mx-lg-auto img-fluid" alt="hero-img" width="700" height="500" loading="lazy"/>
                        </div>
                    }
                    { 
                        // HERO TXT on left side 
                        <div className="col-lg-6">             
                                <h1 className="display-5 fw-bold lh-1 mb-3">{heroTitle ? heroTitle : ``}</h1>   
                                {
                                    !isEmpty(heroText) ? 
                                    <>
                                        {heroText.map(text => (
                                            <>
                                                <p className="lead">
                                                    {text}                                                    
                                                </p>              
                                            </>
                                        ))}
                                    </> : 
                                    <>
                                    </>
                                }   
                                {
                                    linkButton ? 
                                    <>
                                        <div className="d-grid gap-2 d-md-flex justify-content-md-start">
                                            <button 
                                              onClick={e => {goToLink(e, linkButton)}}
                                            type="button" className="btn btn-primary btn-lg px-4 me-md-2"
                                            >
                                            Newsletter Samples
                                            </button>                    
                                        </div>
                                    </> :
                                    <></>
                                }                                         
                            </div>
                        }
                    </div>
                </>
            }

         { 
            emailBox ?
            <>
                <iframe 
                    // scrolling="auto"
                    width="540"             
                    src="https://ebcc5159.sibforms.com/serve/MUIEAL3zP0k-bkBtAuJxn4r_dJSLuxoTm0vNw8ef4pdljBopBIixeHwrp3QrIZTE58eT4lI4Zvgh9aSN8yMIaLQgj6c-Q9SSCyknwaxOx4MPDQ-saYan-be3IKm5FNCRIhZelx7elytFQ-8qBuSsG6dCH9rCOh3TDS9QvHByGQeN0SPFhkvPOdtdTxL9HmKVi1sUdC_Vyk3UiVZh" 			            
                    style={{
                    border: '0',
                    display: "block",
                    marginLeft: 'auto',
                    marginRight: 'auto',                
                    maxWidth: '100%',
                    height: '60vh',		
                }}
            />
            </>: 
            <></>
         }            
         </div>
        }
     </>      
};         