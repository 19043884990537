import React, {useEffect, useState} from 'react'; 
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import Loading from './Loading';
import logo from './RU.PNG';

const DisplayLoader = ({
  isShow,
  loading,
  type  
}) => {
  // learning points:
  // the elements in this component will show even if full-loder parent element has no height 
  // this is because their default CSS state is not set to display none 
  // or it can be we are using inine style which supersedes all other CSS
  return <>     
            {
              isShow ? 
              <>
            <span
              style={{
                margin: '0 auto',
                marginBottom: '10rem',
              }}
            >        
              <img
                key={`business-icon-img`}
                className="business-icon-img"                 
                src={logo}
                width="100"
                height="100"
                alt="business icon"
              />  
            </span>
            <p
                style={{
                  textAlign: 'center',
                  // marginBottom: '5rem',
                  fontSize: '20px',
                  fontWeight: 'bold',
                  padding: '2rem',                  
                }}
              >
                Preparing your dashboard. One moment please.                
              </p>              

              {
                // company card
                <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
                >                
                  <div 
                    style={{
                      maxWidth: '18rem'
                    }}
                    className="card text-white bg-primary mb-3"
                  >
                    <div className="card-header">Header</div>
                    <div className="card-body">
                      <h5 className="card-title">Primary card title</h5>
                      <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                    </div>
                  </div>
                </div>
              }

            <p
              // learning points: 
              // css learning point
              // to center something in the centre of page
              // wrap the element in display:flex then use justify-content:centre
              // then use margin: '0 auto' on the indiviidual item 
              style={{
                margin: '0 auto',
                marginBottom: '5rem',
              }}
            >              
              <Loading
                loading={loading} 
                type={type}                
              />
            </p>    
              </> : <></>
            }                
  </>
}

export default function FullPageLoading({
    // loading, 
    type,
}) {
  // optimisation point: 
  // show random interestng text on full page loading
  // show user a random question on the loading page  
  // more interesting than showing just a loading spinner    
  const useReduxState = () => {
    return useSelector(
      (state) => ({
        loading: state.loading,
        loadingTypes: state.loadingTypes,
        loginTime: state.loginTime,
      }),
      shallowEqual
);};    
const { loading, loadingTypes, loginTime } = useReduxState();
const dispatch = useDispatch();  
const [showSearchDisplay, setSearchDisplay] = useState(false);
    useEffect(() => {         
        // here we need to listen to the following from redux:
        // these two events are executed inside listenAuthChange( )
        // as an Auth change indicates a page change!
        // dispatch(startPageLoading());        
        // dispatch(finishPageLoading());        
          showFullPageLoading();        
    }, [loading]); 
    
    const showFullPageLoading = () => {      
      // is this the correct place to put this function?
      // this function should be placed inside useEffect
      // the FullPageLoading componnet listens for changes in redux and starts the transition itself 
      // when loading finishes it removes the class from fullpage-loader class            
      var fullPageLoader = document.querySelector('.fullpage-loader');
      console.log('log showFullPageLoading', fullPageLoader);
      // if loading is true show search display 
      // and add class 
      if(fullPageLoader && loading) {
        fullPageLoader.classList.toggle("expand-bottom-nav");
        if (!showSearchDisplay) setSearchDisplay(true);        
      }; 
      // if loading is true show search display and remove expand-bottom-nav
      if(fullPageLoader && !loading) {
        fullPageLoader.classList.toggle("expand-bottom-nav");        
        if (showSearchDisplay) setSearchDisplay(false);
      }; 

      
  };
  console.log('log showSearchDisplay', showSearchDisplay);       
    return <>
    <div className='fullpage-loader'>
      {      
        showSearchDisplay ? <>        
        <DisplayLoader
            isShow={showSearchDisplay}
            loading={loading} 
            type={type}
          />      
        </> : 
        <></>
      }    
    </div>
    </>
}; 

