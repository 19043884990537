import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import isEmpty from 'lodash.isempty';
import AuthButtons from './AuthButtons';
import firebaseSer from './utils/services/FirebaseService';
import logo from './RU.PNG';

const Item = ({item, keyInt}) => {    
    const dispatch = useDispatch();   
    const logout = (e) => {
        e.preventDefault();    
        firebaseSer.logout(dispatch);
    };
    return <>              
          <span
            key={`b${keyInt}`}
          >            
            {item === 'Home' ? (                  
            <img
                key={`c${keyInt}`}
                className="business-icon-img"                 
                src={logo}
                width="50"
                height="50"
                alt="business icon"
            />              
            ) : (<></>)}
            {item === "Pricing" ? (
                <i key={`d${keyInt}`} className="fa fa-credit-card" />
                ) : ( <></>
            )}
            {item === "Modules" ? (
                <i key={`d${keyInt}`} className="fas fa-folder-open" />
                ) : ( <></>
            )}
            {item === "Books" ? (
                <i key={`d${keyInt}`} className="fas fa-book" />
                ) : ( <></>
            )}            
            {item === "Newsletter" ? (
                <i key={`d${keyInt}`} className="fas fa-newspaper" />
                ) : ( <></>
            )}                                                              
            {item === "Blogs" ? (                
                <i key={`d${keyInt}`} className="fas fa-blog" />
                ) : ( <></>
            )}            
            {item === "Youtube" ? (
                <i key={`d${keyInt}`} className="fab fa-youtube" />
                ) : ( <></>
            )}
            {item === "Help" ? (
                <i key={`d${keyInt}`} className="far fa-comments" />
                ) : ( <></>
            )}
            {item === "Logout" ? (
                <i 
                id={'logout-button'}
                style={{
                    fontSize: '1.5rem',                
                    marginRight: '2rem'
                }}
                className="fa fa-sign-out"
                onClick={(e) => {logout(e)}}                             
                />
                ) : ( <></>
            )}
            </span>    
    </>
  };


const PublicNavbar = ({
    isAuthenticated
  }) => {  
    const [navbarItems, setBottomMenuItems] = useState([]);    
    let itemNames = [
        'Home', 'Blogs',
        // 'Newsletter',
    ];
    
    useEffect(() => {                                        
        setBottomMenuItems(itemNames);        
    }, [isAuthenticated]);     
    
    const goTo = (name) => {
        let url1 ='https://companyrepo.com';
        let url2 ='https://companyrepo.com/blog';        
        if(name === 'Home') {
            window.open(
                `${url1}`,
                "_blank"
          );        
        }
        if(name === 'Blogs') {
            window.open(
                `${url2}`,
                "_blank"
          );        
        }                
    };
    
    console.log(`log PublicNav`, isAuthenticated);

    return (
        <>         
            {isEmpty(isAuthenticated) ? 
            <>
            <ul className='mobile-auth-navbar'>          
            {
            navbarItems.length > 0 ? 
                navbarItems.map((item, index) => (                    
                    <li 
                    className={''}
                    key={index}
                    onClick={() => goTo(item)}
                  >                                                    
                        <Item                         
                            item={item}
                            keyInt={index}                            
                        />
                </li>
                )) : <></>
            }   
            </ul>
            </> : 
            <></>}
        </>
)}; 


const AuthenticatedNavbar = ({
    isAuthenticated
  }) => {  
    useEffect(() => {      
    },[isAuthenticated])    
    return (
        <>         
            {!isEmpty(isAuthenticated) ? 
            <>                
            </> : 
            <></>}
        </>
)}; 

export default function Navbar({}) {  
    // leanring points: 
    // any variable can be used to check whether user is logged in or not 
    // profile, etc but we should use a simple variable like loginTime    
    // future design options:
    // top navbar disappears after auethentication in mobile view
    // show bottom navbar instead 
    // but in desktop view still show top navbar
    const useProfile = () => {
      return useSelector(
          (state) => ({            
              profile: state.profile, // need profile for photo icon url              
              loginTime: state.loginTime,
          }),
          shallowEqual
      )};        
        const { profile, loginTime } = useProfile();    
        useEffect(()=> {            
            console.log(`navbar mounted`) 
        }, [profile, loginTime]);
        return <>            
            {isEmpty(loginTime) ?
                <>
                <PublicNavbar isAuthenticated={loginTime}/>
                <AuthButtons/>
                </>
            :                           
              <>
                <AuthenticatedNavbar isAuthenticated={loginTime}/>
                <AuthButtons/>
              </>            
            }            
        </>            
};         


